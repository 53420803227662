<template lang="pug">
  div( :id="idModal" class="modal fade" role="dialog")
    .modal-dialog
      .modal-content
        .modal-header
          p #[translate Producto bajo pedido]
          button(@click="next()" type="button" class="close")
            img(:src="img.close" width="25")
          span.hidden(@click="next()" type="button" class="close" data-dismiss="modal" ref="close")
            img(:src="img.close" width="25")
        .modal-body.tw-text-center
            p #[translate Producto añadido al carro]
            p #[translate Este producto se sirve bajo pedido, plazo de entrega superior a estándar. Este producto no admite devolución.]
        .modal-footer
          button(@click="next()") #[translate Aceptar]          
    span(class="hidden") #[translate xxx#/]
</template>
<script>
import * as types from '@/store/mutation-types'
export default {
  name: 'ModalImage',
  props: ['id', 'title', 'content', 'imagen', 'aProduct'],
  data () {
    return {
      viewGrid: 'grid',
      img: {
        close: require('@/assets/img/icos/x_cerrar.svg'),
        modal: require('@/assets/img/modal-soste.svg')
      },
      migas: [],
      countOthersProductsAdds: 0
    }
  },
  computed: {
    idModal () {
      if (this.id) {
        return this.id
      }
      return 'modal-on-demand'
    }
  },
  created () {
  },
  methods: {
    next () {
      this.$refs.close.click()
      this.$store.commit(types.MODAL_ON_DEMAND, false)
    },
  }
}
</script>
<style lang="scss" scoped>
  .modal{
    max-width:830px;
    margin: 0 auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .modal-dialog{
    max-width: 830px;
    width: max-content;
  }
  .modal-content{
    border: none;
    @include roundAll(10);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    box-shadow: 0 3px 6px rgba(0,0,0,.25);
    overflow: hidden;
    .catalog-GridItem{
      float: left;
    }
  }
  .modal-header{    
    padding:0;
    background-color:#E1EDFE;
    border:0;
    position:relative;
    padding-top:20px;
    padding-left: 18px;
    padding-bottom:20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .text-center{
      text-align: center;
    }
    p{
      font-size: 18px;
    }
    .close{
      margin-top: 0;
      right:20px;
      font-size: 40px;
      opacity: 1;
      position: absolute;
      top:10px;
    }
  }

  .modal-body{
    width: max-content;
    max-width: 830px;
    min-width: 540px;
    padding: 90px 20px;
    font-size: 14px;
    font-family: "Raleway", Arial, Verdana;
    max-height: 356px;
    .product-list{
      padding: 0;
    }
    .row{
      margin-right: 0;
      margin-left: 0;
      padding: 0;
    }
    .catalog-GridItem{
      border: 1px solid #EAEDED;
      margin-bottom: 0;
      width: 270px;
      .img-cn{
        float:left;
        width: 40%;
      }
      .text-center{
        float:right;
        width: 60%;
      }
      .code-cn{
        float:right;
        width: 60%;
      }
    }
  }

  .modal-footer{
    background-color:#fff;
    text-align: center;
    button{
      background-color: #01acfc;
      color:#fff;
      padding:10px 30px;
      border:none;
    }
  }

  h2{
    font-size: 18px;
    margin: 0;
  }

  .container{
    max-width: 830px;
    width: max-content;
  }
</style>
