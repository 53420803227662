<template lang="pug">
  .pedido-comprar
    .box-1
      .container
        .row
          .col-sm-8
            bk-search( @toSearchCategory="toSearchCategory" :categories="aCat" type="orderBuy")
          .col-sm-4

    .box-2
      .container
        .row
          .col-sm-12
            bk-filter-top(@toChangeFilterProduct="toChangeFilterProduct" @toChangeOrdering="toChangeOrdering" @toChangeCat="toChangeCat" @toGetCat="toGetCat")
    .box-3
      bk-grid( :query="query" :ordering="ordering" :typeProduct="typeProduct" :category="cat" :categories="aCat")
</template>

<script>
import BkSearch from '@/components/catalog/Search.vue'
import BkFilterTop from '@/components/common/Filter-top.vue'
import BkGrid from '@/components/order/buy/Grid.vue'

export default {
  name: 'pedidosComprar',
  components: {BkSearch, BkFilterTop, BkGrid},
  data () {
    return {
      'query': '',
      'ordering': '', // Ordenación de producto
      'typeProduct': '', // Si es autorizado o no.
      'cat': '', // Categorías de producto.
      'aCat': [] // Guardamos todas las categorías.
    }
  },
  computed: {
  },
  methods: {
    toSearchCategory (aDatos) {
      // console.log(`toSearchCategory -- query: ${aDatos[0]}, idCat: ${aDatos[1]}`)
      this.query = aDatos[0]
      this.cat = aDatos[1] // asignar categoría, si viene categoría del buscador.
    },
    toChangeFilterProduct (v) {
      // console.log(`toChangeFilterProduct: ${v}`)
      this.typeProduct = v
    },
    toChangeOrdering (v) {
      // console.log(`changeOrderProduct: ${v}`)
      this.ordering = v
    },
    toChangeCat (v) {
      // console.log(`toChangeCat: ${v}`)
      this.cat = v
    },
    toGetCat (aCat) {
      this.aCat = aCat
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
  .box-1{
    padding: 14px 0;
    background-color: #EFEFEF;
  }

  .box-2{
    text-align: right;
    padding: 15px 0;
  }
</style>
