<template lang="pug">
  #ClientListGrid
    .container(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}
    .holder-cn
      .container
        .row.box-1
          div(:class="{'col-sm-8': !config_app.client_locked, 'col-sm-6': config_app.client_locked}")
            p.name
              translate Seleccionar Cliente
              small(:class="{'fade-in': count}")  ({{count}} #[translate clientes])
          .col-sm-2.client-code
            p.text-center
              span #[translate Código Cliente]
          .col-sm-2( v-if="config_app.client_locked")
            p.text-center #[translate Bloqueado]
          .col-sm-2
              p.text-center #[translate Nº de centros]
      .container(v-if="count == 0")
        .row
          .col-sm-12
            div(class="alert alert-info" role="alert") #[translate No se han encontrado clientes].
      bk-item(v-if="count" v-for="(client, index) in clients" :key="client.id" :client="client" :index="index")
      .container.text-center(v-if="pages > 1")
        ek-paginate(:page-count="pages"
          :page-range="4"
          :margin-pages="2"
          :click-handler="pagChange"
          :prev-text="paginationTxt.prev"
          :next-text="paginationTxt.next"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :initial-page="currentPage-1"
          v-show="pages")

</template>
<script>
import EkPaginate from 'vuejs-paginate'
import BkItem from '@/components/client/Item.vue'

export default {
  name: 'ClientListGrid',
  props: ['clients', 'message', 'pages', 'count', 'currentPage'],
  data () {
    return {
      paginationTxt: {
        'prev': this.$gettext('Anterior'),
        'next': this.$gettext('Siguiente')
      }
    }
  },
  components: { BkItem, EkPaginate },
  computed: {
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.$emit('toPaginate', [pageNum])
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>

  .box-1{
    padding-top:24px;
    font-size:16px;
    color:#494f58;
    font-weight:$light;
    border-bottom: 1px solid #F1F1F3;
    margin-bottom: 6px;

    .client-code{
      span{
        display: inline-block;
        min-width: 102px;
        text-align: right;
      }
    }
  }

  .holder-cn{
    .ClientListItem{
      // border: 1px solid #fff;

      &:nth-child(even){
        // border: 1px solid #F5F6FA;
        background-color: #F1F4F7;
      }

      &:last-child{
        border-bottom: 1px solid #1C2C40;
      }
    }
  }

  .name{
    margin-left:-15px;
    font-weight: $medium;
    small{
      font-size: 16px;
    }
  }

  .alert{
    margin-top:40px;
  }

</style>
