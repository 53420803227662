<template lang="pug">
  .category-featured(v-show="aCat.length > 3")
    .container
      .row
        .col-sm-12
          h2 #[translate Las categorías más populares en] {{catActive.name}}
      .row.list-cn
        .col-sm-3(v-for="item in aCat" :key="item.id")
          a(href="#" @click="setCategory(item)")
            img(:src="item.image_url")
            strong {{item.name}}
</template>
<script>
export default {
  name: 'CatalogCatFeatured',
  props: ['catActive'],
  data () {
    return {
    }
  },
  computed: {
    aCat () {
      let cat = []
      this.catActive.children.map((item, index) => {
        // console.log(`${index} - ${item.image_url}`)
        if (item.image_url && cat.length < 5) {
          cat.push(item)
        }
      })
      return cat
    }
  },
  methods: {
    setCategory (item) {
      // console.log('setCategory:', item)
      if (item.level === 2) {
        window.$(`#catalog-menu button[data-id="${item.parent}"]`).click()
        window.$(`#catalog-menu button[data-id="${item.id}"]`).click()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.category-featured{
  text-align: center;
  padding: 20px 0 40px;

  h2{
    font-size: 18px;
    font-weight: $light;
    margin: 8px 0 22px;
  }
}
.list-cn{
  strong{
    display:block;
    font-size: 14px;
    font-weight: $medium;
    margin: 7px 0 16px;
  }
  img{
    @include roundAll(20);
    // border: 1px solid #707070;
    @include transition();
  }

  a{
    color: $color-text;
    @include transition();
    display: block;

    &:hover, &:focus{
      text-decoration: none;
      opacity: .8;

      img{
        @include box-shadow(bottom, 5px, rgba(#000, .2));
        transform: translateY(-6px);
      }
    }
  }
}
</style>
