<template lang="pug">
  div( :id="idModal" class="modal fade" role="dialog")
    .modal-dialog
      .modal-content
        .modal-header
          p 
            strong #[translate Producto sin stock]&nbsp;
          button(@click="next()" type="button" class="close")
            img(:src="img.close" width="25")
          span.hidden(@click="next()" type="button" class="close" data-dismiss="modal" ref="close")
            img(:src="img.close" width="25")
          
        .modal-body
          .container
          .row
            .col-sm-12.product-list
              p Actualmente no tenemos stock del artículo #[strong {{ externalId }}, {{name }}], se lo serviremos en cuanto vuelva a estar disponible. #[br ] #[br ] Consulte nuestros productos alternativos en existencias o póngase en contacto con nosotros para más información.
                      
        .modal-footer
          button(@click="next()") #[translate Continuar]          
    span(class="hidden") #[translate xxx#/]
</template>
<script>
export default {
  name: 'ModalImage',
  props: ['id', 'title', 'content', 'imagen', 'product'],
  data () {
    return {
      viewGrid: 'grid',
      img: {
        close: require('@/assets/img/icos/x_cerrar.svg')
      },
      migas: [],
      countOthersProductsAdds: 0
    }
  },
  computed: {
    idModal () {
      if (this.id) {
        return this.id
      }
      return 'modal-stock-0'
    },
    externalId () {
      if (this.product && this.product.product.external_id) {
        return this.product.product.external_id
      }
      return ''
    },
    name () {
      if (this.product && this.product.product.name) {
        return this.product.product.name
      }
      return ''
    }
  },
  created () {
  },
  methods: {
    next () {
      this.$refs.close.click()
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal{
    max-width:600px;
    margin: 0 auto;
  }
  .modal-dialog{
    max-width: 600px;
    width: max-content;
  }
  .modal-content{
    border: none;
    @include roundAll(0);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    box-shadow: 0 3px 6px rgba(0,0,0,.25);
    overflow: hidden;
    .catalog-GridItem{
      float: left;
    }
  }
  .modal-header{    
    padding:0;
    background-color:#EAEDED;
    border:0;
    position:relative;
    padding-top:20px;
    padding-left: 18px;
    .text-center{
      text-align: center;
    }
    .dibujo{
      position:relative;
    }
    p{
            font-size: 14px;

      strong{
        font-size: 1.8rem;
      }
      span {
        font-size: 1.6rem;
      }
    }
    .close{
      margin-top: 0;
      right:20px;
      font-size: 40px;
      opacity: 1;
      position: absolute;
      top:10px;
    }
  }

  .modal-body{
    width: max-content;
    max-width: 600px;
    min-width: 540px;
    padding: 0;
    font-size: 16px;
    font-family: "Raleway", Arial, Verdana;
    max-height: 336px;
    overflow-y: auto;
    .product-list{
      padding: 30px;
    }
    .row{
      margin-right: 0;
      margin-left: 0;
      padding: 0;
    }
    .catalog-GridItem{
      border: 1px solid #EAEDED;
      margin-bottom: 0;
      width: 270px;
      .img-cn{
        float:left;
        width: 40%;
      }
      .text-center{
        float:right;
        width: 60%;
      }
      .code-cn{
        float:right;
        width: 60%;
      }
    }
  }

  .modal-footer{
    background-color:#EAEDED;
    text-align: center;
    button{
      background-color: #FF7343;
      color:#fff;
      padding:10px 30px;
      border:none;
    }
  }

  h2{
    font-size: 18px;
    margin: 0;
  }

  .container{
    max-width: 600px;
    width: max-content;
  }
</style>
