<template lang="pug">
  #CenterItemSimple.container
    .row.box-1(:class="{'par': index%2}")
      div(:class="{'col-sm-12': typeSearch === 2, 'col-sm-6': typeSearch !== 2}" @click="setItem" data-dismiss="modal")
        p {{item.name}}
          br
          | #[translate Identificador]: {{item.external_id}}
      .col-sm-3(v-if="typeSearch !== 2" @click="setItem" data-dismiss="modal")
        p {{item.address}}
      .col-sm-3(v-if="typeSearch !== 2" @click="setItem" data-dismiss="modal")
        p {{item.locality}}

</template>
<script>
// importamos servicios Center
import SrvCenter from '@/services/center'

export default {
  name: 'CenterListItemSimple',
  props: ['item', 'index', 'typeSearch', 'products'],
  data () {
    return {
    }
  },
  methods: {
    checkEmpty (v) {
      return (v === null || v === '') ? '-' : v
    },
    setItem () {
      if (this.typeSearch === 1) {
        // Eliminamos el Cliente seleccionado si existe;
        this.$store.dispatch('removeClient')
        this.$store.commit('REMOVE_CLIENT')

        // Eliminamos el centro seleccionado si existe;
        this.$store.dispatch('removeCenter')
        this.$store.commit('REMOVE_CENTER')

        // Enviamos a Store Client los datos del cliente seleccionado
        this.$store.dispatch('setClient', this.item)
        this.$store.commit('SET_CLIENT')

        this.$store.dispatch('deleteCart')
      
        this.products.map((item, index) => {
          if (this.config_app.id_code_0 !== item.product.id) {
            let IdAditionalMeasurementUnit = item.aditional_measurement_unit ? item.aditional_measurement_unit.id : null
            this.$store.dispatch('addCart', {idProduct: item.product.id, quantity: item.quantity, product: item.product, aditional_measurement_unit: IdAditionalMeasurementUnit, hide: true})
          }
        })

        this.$router.push({'name': 'cartList'})
      }

      if (this.typeSearch === 3) {
        // Enviamos a Store Client los datos del cliente seleccionado
        this.$store.dispatch('setClientCatalog', this.item)
        if (this.item.center_count == 1) {
          SrvCenter.getCentersByClient('', 1)
          .then(res => {
            // console.log(`---> Respuesta servicio Search center modal: `, res)
            if (res === 0) {
            } else if (res.status === 200) {
              if (res.data.count === 1) {
                this.$store.dispatch('setCenterCatalog', res.data.results[0])
              }
            } else {
              this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            }
          }, () => {
            // console.log('----->' + error)
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          })
        } else {
          this.$store.dispatch('setCenterCatalog', null)
        }
      }
    }

  },
  computed: {
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  }
}
</script>
<style lang="scss" scoped>

  .CenterItemSimple{
    color:#494f58;
  }
  .box-1{
    @include transition();
    font-size: 14px;
    color: #4D4F5C;
    font-weight: $regular;
    border:1px solid #fff;
    cursor: pointer;

    & > *{
      padding-top:12px;
      padding-bottom:8px;
    }

    &:hover, &:focus{
      background-color: rgba(#129FE9, .2);
      border:1px solid #00ADFC;

      .name{
        font-weight: $medium;
      }
    }

    p{
      margin:0;
    }
  }
  .name, .adress, .loca{
    text-transform: uppercase;
    @include ellipsis();
    cursor: pointer;
    // margin-left:-15px;
  }

  .name{
    margin-left: 34px;
    display:block;
    @include transition();
  }


</style>
