<template lang="pug">
  .item-cn
    .row
        .product-cn(:class="{'col-sm-4': isTypeApp !== 'orbishigiene' && isTypeApp !== 'ghgorbis' && isTypeApp !== 'arablau' , 'col-sm-6': isTypeApp === 'orbishigiene' || isTypeApp === 'ghgorbis' || isTypeApp === 'arablau'}")
          picture.img-cn
            img(:src="checkEmptyImg(product.image_url)")
          template(v-if="product.external_id === '0'")
            h3 {{ entries.description }}
          template(v-else)
            h3 {{ product.name }}

        .col-sm-3
          ul
            // li(v-if="product.supplier" class="prov-cn") #[translate Prov].: {{product.supplier.name}}
            li(v-if="isTypeApp === 'pedrosa'") #[translate Ref.] {{product.external_id}}
            li(v-else) #[translate Código]: {{product.external_id}}
            li.literalFormat {{product.measurement_unit.quantity}} unidades por formato ({{product.measurement_unit.name}})
            li.literalFormat(v-if="product.aditional_measurement_units.length > 0") {{product.aditional_measurement_units[0].quantity}} unidades por formato ({{product.aditional_measurement_units[0].name}})

        //.col-sm-2(v-if="isTypeApp !== 'orbishigiene' && isTypeApp !== 'ghgorbis' && isTypeApp !== 'arablau'")
          p.und(:class="{'noStock': !product.stock}") {{product.stock}} #[translate Und].
        .col-sm-2.text-right
          p {{entries.price | format-money}} {{product.currency_symbol}}
        .col-sm-1.text-right
          p {{entries.quantity}}
        .col-sm-1.text-right
          p {{ itemFormato }}
        .col-sm-1.text-right
          p {{priceTotal | format-money}} {{product.currency_symbol}}

          // p.facturar-cn(v-if="isTypeApp !== 'pedrosa' && isTypeApp !== 'maripol'") #[translate Facturar]
            span.group
              small(:class="{'active on':isFacturable}") #[translate Si]
              small(:class="{'active':!isFacturable}") #[translate No]
    //- .row(v-if="parseInt(product.id) === parseInt(config_app.id_code_0)")
    //-   .col-sm-1.product-cn
    //-   .col-sm-11
    //-     p.descriptionCode0 {{ entries.description }}
</template>
<script>
export default {
  name: 'PedidoSingleItem',
  props: ['entries', 'billable'],
  data () {
    return {
      isFacturable: false,
      imgDefault: require('@/assets/img/no_disponible.png')
    }
  },
  computed: {
    itemFormato () {
      if (this.entries.aditional_measurement_unit) {
        return this.entries.aditional_measurement_unit.name
      } else {
        return this.entries.product.measurement_unit.name
      }
    },
    product () {
      return this.entries.product
    },
    priceTotal () {
      // return this.entries.price * this.entries.quantity
      return this.entries.base
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  created () {
    this.isFacturable = this.billable
  },
  methods: {
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    }
  }
}
</script>
<style lang="scss" scoped>
.literalFormat{
  font-size: 10px;
}
.descriptionCode0{
  background-color: #f1f4f7;
  padding:10px;
}
  .item-cn{
    margin:0;
    padding: 20px 0 30px;
    border-bottom: 1px solid #979797;
    color:#4D4F5C;
  }
  .row{
    display: flex;
    align-items: center;
  }

  .product-cn{
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 100px;
    font-size: 14px;
  }

  .img-cn{
    position:absolute;
    top:0;
    bottom:0;
    left:6px;
    width: 78px;
    min-height:58px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: $white;

    img{
      max-width: 100%;
      max-height: 54px;
      height:auto;
    }
  }

  h3{
    color:#1C2C40;
    font-size: 13px;
    text-transform: uppercase;
    margin:0;
    font-weight: $regular;
    // @include ellipsis();
  }

  ul{
    list-style: none;
    padding:0;
    font-size: 12px;
  }

  .und{
    font-size:14px;
    color:#61c844;

    &.noStock{
      color:#ff5466;
    }
  }
</style>
