
// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

// console.log('token service Client: ' + window.localStorage.getItem('_token'))

export default {
  getClients (querySearch, page, pageSize = 100) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      page = (page === undefined) ? 1 : page
      let countryTmp = Stores.getters.userInfo.country
      let country = (countryTmp && countryTmp.id !== 'eu') ? countryTmp.id : ''

      // llamada a la API de clientas
      // console.log(`getClients token: ${window.localStorage.getItem('_token')}`)
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/clients/?search=${querySearch}&page=${page}&country=${country}&page_size=${pageSize}`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Store res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getClientById (id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API de clientas
      // console.log(`getClients token: ${window.localStorage.getItem('_token')}`)
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/clients/${id}/`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Store res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }
}
