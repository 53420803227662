<template lang="pug">
  #ofertas-home
    transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
      router-view
</template>

<script>

export default {
  name: 'OfertasHome',
  components: {},
  data () {
    return {
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
