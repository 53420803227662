<template lang="pug">
  #popupOrderCenter(class="modal fade" :class="classOpen" role="dialog")
    .modal-dialog.modal-lg
      .modal-content(v-if="center")
        .modal-header
          .row-flex
            h2 {{center.name}}
            button(type="button" class="close" data-dismiss="modal")
              img(:src="img.close" width="12")
        .modal-body
          .container
            .row.center-box
              .col-sm-4
                p #[translate Identificador]: #[strong {{checkEmpty(center.external_code)}}]
                p #[translate Dirección]: #[strong {{checkEmpty(center.address)}}]
                p #[translate Localidad]: #[strong {{checkEmpty(center.locality)}}]
                p #[translate Provincia]: #[strong {{checkEmpty(center.province)}}]
                p #[translate Código Postal]: #[strong {{checkEmpty(center.postal_code)}}]
              .col-sm-4
                p #[translate Persona de contacto]: #[strong {{checkEmpty(center.contact)}}]
                p #[translate Teléfono]: #[strong {{checkEmpty(center.phone)}}]
                p #[translate Horario]: #[strong {{center.morning_open_times}} - {{center.afternoon_open_times}}]
                p #[translate Observaciones]: #[strong {{checkEmpty(center.observations)}}]
              .col-sm-4.center-bar(:class="{ 'hidden' : !center, 'group-cn': 'purchase_limit > 0'}" v-if="purchase_limit > 0")
                p.limite-cn
                  translate Límite:
                  span.limit-js &nbsp;{{purchase_limit | format-money}}€
                p.consumo-cn
                  translate Consumo
                  span.porcen-js {{purchase_limit_percent}}%:
                  span.consumo-js {{purchase_current | format-money}}€
                p.bar-cn
                  span.porcent-bg
                  span.porcent-bar(:class="classBarConsumo" :style="widthBarConsumo")
            .row.client-box
              .col-sm-5
                div.client-cn
                  p #[translate Cliente]:
                  p {{client.name}}
                    |
                    br
                    |
                    span(v-if="client.external_code") Cod. {{client.external_code}}
</template>

<script>
import SrvCenter from '@/services/center'

export default {
  name: 'popupOrderCenter',
  components: {},
  props: ['classOpen'], // clase para abrir el popup
  data: () => ({
    'center': null,
    'client': null,
    'img': {
      'close': require('@/assets/img/icos/cerrar-01.svg')
    }
  }),
  created () {
  },
  computed: {
    purchase_limit () {
      // límite - cuando purchase_limit= '0' no tienes que mostrar nada.
      if (this.center) {
        const limit = this.center.purchase_limit // this.$options.filters.formatMoney(this.center.purchase_limit)
        return limit
      }
      return 0
    },
    purchase_limit_percent () {
      // porcentaje del límite
      if (this.center) {
        return this.center.purchase_limit_percent
      }
      return 0
    },
    purchase_current () {
      if (this.center) {
        return this.center.purchase_current
      }
      return 0
    },
    classBarConsumo () {
      if (this.purchase_limit_percent) {
        if (this.purchase_limit_percent < 70) {
          return 'min'
        } else if (this.purchase_limit_percent >= 70 && this.purchase_limit_percent <= 90) {
          return 'medium'
        } else {
          return 'max'
        }
      }
    },
    widthBarConsumo () {
      if (this.purchase_limit_percent) {
        return `width:${this.purchase_limit_percent}%;`
      }
      return 'width:0%;'
    },
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }

  },
  methods: {
    checkEmpty (v) {
      return (v === null || v === '') ? '-' : v
    },
    openModal (data) {
      this.getCenter(data[0].id)
      this.client = data[1]
      // this.id = id
      // this.getProduct()
    },
    getCenter (id) {
      SrvCenter.getCenter(id)
        .then(res => {
          // console.log(`center seleccionado: `, res.data)
          if (res.status === 200) {
            this.center = res.data
          }
        }, () => {
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-content{
    border: 1px solid #17296E;
    @include roundAll(5);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    box-shadow: 0 3px 6px rgba(0,0,0,.25);
    overflow: hidden;
  }
  .modal-header{
    background-color: #E1EDFE;
    padding: 20px 30px 14px;
    border-bottom: 1px solid #ABC7F1;

    .row-flex{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h2{
      font-size: 18px;
      color: #1C2C40;
      margin: 0;
    }

    .close{
      float: none;
      opacity: 1;
    }
  }

  .modal-body{
    padding: 28px;
  }

  .client-box{
    border-top: 1px solid #ABC7F1;
    padding-top: 26px;
    margin-top: 30px;
  }

  .client-cn{
    display: flex;

    p{
      font-size: 14px;
      line-height: 24px;

      &:not(:last-child){
        margin-right: 8px;
      }
    }
  }

  .center-box{
    strong{
      font-weight: $medium;
    }
  }

  .consumo-cn{
    span:not(:first-child){
      margin-left: 4px;
    }
  }

  .bar-cn{
    // display:none;
    position: relative;
    display: block;
    max-width: 200px;
    height: 6px;
    margin-top: 2px;
    overflow: hidden;
    @include roundAll(4);

    span{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      &.porcent-bg{
        width: 100%;
        background-color: #dce5e8;
      }

      &.porcent-bar{
        width: 0%;

        &.min{
          background-color: #99c46c;
        }

        &.medium{
            background-color: #F7B633;
        }

        &.max{
            background-color: #ff8b61;
        }
      }
    }
  }
</style>
