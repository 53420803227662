<template lang="pug">
  #pedidosValidar
    // Mostramos listado
    div(v-if="$route.name !== 'validarPedidoSingle'")
      .box-2
        .container
          .row
            .col-sm-8
              bk-search(@toSearch="toSearch" :num="ordersNum" v-if="ordersNumPendientes || ordersNum")
            .col-sm-4.text-right

      // Listado Pedidos Pendientes
      .box-3(v-if="ordersNumPendientes")
        .container
          .row
            .col-sm-8
              p.info-cn #[translate Pedidos pendientes de validar]
                small(:class="{'fade-in': ordersNumPendientes}")  ({{ordersNumPendientes}} #[translate Pedidos])
            .col-sm-4.text-right

      bk-grid(
        :orders="aOrdersPendientes"
        typeGrid="pendientes"
        :message="message"
        pages="0"
        :count="ordersNumPendientes"
        currentPage="1")

      // Listado Pedidos validados
      .box-3(v-if="ordersNum")
        .container
          .row
            .col-sm-8
              p.info-cn #[translate Pedidos validados]
                small(:class="{'fade-in': ordersNum}")  ({{ordersNum}} #[translate Pedidos])
            .col-sm-4.text-right
              date-picker(class="calendar-cn" v-model="calendar.date" :lang="calendar.lang" :format="calendar.format" type="date" :first-day-of-week="calendar.firstDayOfWeek" range :clearable="calendar.clearable" :shortcuts="calendar.shortcuts" not-before="2016-01-01" @input="changeCalendar" confirm)

      bk-grid(
        :orders="aOrders"
        typeGrid="validados"
        :message="message"
        :pages="totalPages"
        :count="ordersNum"
        :currentPage="currentPage"
        @toPaginate="pagChange")

    // Mostramos el detalle del pedido.
    div(v-if="$route.name == 'validarPedidoSingle'")
      transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
        router-view
</template>
<script>
import appConfig from '@/config-app.js'
import BkSearch from '@/components/validar-pedidos/Search.vue'
import BkGrid from '@/components/validar-pedidos/Listado.vue'
import DatePicker from 'vue2-datepicker'
// importamos servicios Catálogo
import SrvValidarPedidos from '@/services/validar-pedidos'

export default {
  name: 'PedidosValidar',
  props: [],
  components: { BkSearch, BkGrid, DatePicker },
  data () {
    return {
      aOrders: '', // Pedidos validados.
      ordersNum: null, // Num de pedidos validados.
      aOrdersPendientes: '', // Pedidos no validados.
      ordersNumPendientes: null, // Num de pedidos no validados.
      message: '',
      totalPages: 0,
      init: false, // Para comprobar si es la primera vez que llamamos a la API
      currentPage: 0, // Para saber porque página vamos en la paginación
      query: '', // Donde se guarla la palabra a buscar.
      'calendar': {
        'before': this.$moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        'after': this.$moment().add({'days': -30}).format('YYYY-MM-DD[T]HH:mm:ss'),
        'date': [this.$moment().add({'days': -30}).format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'MM/YYYY', // 'MMM YYYY'
        'firstDayOfWeek': 1
      }
    }
  },
  created () {
    this.getPedidosPendientes()
    this.query = ''
    this.currentPage = 1
    this.search()
  },
  computed: {
    country () {
      return this.$store.getters.userInfo.country
    },
    center () {
      return this.$store.getters.center
    },
    client () {
      return this.$store.getters.client
    },
    validable_orders () {
      // Número de pedidos pendientes.
      return this.$store.getters.userInfo.validable_orders
    }
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.currentPage = pageNum
      this.search()
    },
    toSearch (query) {
      this.query = query
      this.currentPage = 1
      this.search()
    },
    search (query, page) {
      window.scrollTo(0, 0) // movemos el scroll arriba
      SrvValidarPedidos.getPedidosValidados(this.query, this.currentPage, this.calendar.before, this.calendar.after)
        .then(res => {
          if (res.status === 200) {
            this.aOrders = res.data.results
            this.ordersNum = res.data.count
            this.totalPages = res.data.total_pages
            this.currentPage = page
            // console.log(`ordersNum: ${this.ordersNum}`)
            // console.log('order:', res.data.results[0])
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeCalendar (value) {
      let aDate = value.toString().split(',')
      if (aDate.length === 2) {
        this.calendar.after = this.$moment(aDate[0]).format('YYYY-MM-DD[T]HH:mm:ss')
        this.calendar.before = this.$moment(aDate[1]).format('YYYY-MM-DD[T]HH:mm:ss')
        // console.log(`${this.calendar.after} - ${this.calendar.before}`)
        this.search()
      }
    },
    getPedidosPendientes () {
      SrvValidarPedidos.getPedidosPendientes('', 1)
        .then(res => {
          if (res.status === 200) {
            this.aOrdersPendientes = res.data.results
            this.ordersNumPendientes = res.data.count
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    }
  },
  watch: {
    country () {
      // actualizamos el valor de los pedidos pendientes.
      this.currentPage = 1 // Inicializamos la páginación
      this.search()
    },
    center () {
      // console.log('cambio center:', this.center)
      this.currentPage = 1 // Inicializamos la páginación
      this.search()
    },
    client () {
      // console.log('----cambio cliente:', this.client)
      this.currentPage = 1 // Inicializamos la páginación
      this.search()
    },
    validable_orders () {
      // console.log('watch número de pedidos.')
      this.getPedidosPendientes()
    }
  }
}
</script>
<style lang="scss" scoped>
.box-2{
  padding: 14px 0;
  background-color: #EFEFEF;
}

.box-3{
  padding: 26px 0 20px;

  .row{
    display: flex;
    align-items: center;
  }

  .info-cn{
    font-size: 16px;
    margin: 0;

    small{
      font-size: 16px;
    }
  }
}
</style>
