/*

  ref:
  https://codepen.io/Karl53/pen/MbYrPN

*/
import { Bar } from 'vue-chartjs'
import appConfig from '@/config-app.js'

export default {
  extends: Bar,
  props: ['valuesData'],
  data () {
    return {
      'options': {
        'responsive': true,
        'maintainAspectRatio': false,
        'legend': {
          'display': false // Oculta el Label del Dataset
        },
        'tooltips': appConfig.dashboardConfig.tooltips,
        'animation': {
          // 'animateScale': true,
          'animateRotate': true,
          'onComplete': function () {
          }
        },
        'scales': {
          'yAxes': [
            {
              'id': 'y-left',
              'type': 'linear',
              // 'display': true,
              'stacked': false,
              'position': 'left',
              'ticks': {
                'display': true,
                'min': 0,
                // 'max': 800,
                'fontColor': '#2F5EC4',
                'fontSize': 14
              },
              'gridLines': {
                'drawOnChartArea': true // only want the grid lines for one axis to show up
              }
            },
            {
              'id': 'y-right',
              'type': 'linear',
              // 'display': true,
              'stacked': false,
              'position': 'right',
              'ticks': {
                'display': true,
                'min': 0,
                // 'max': 100,
                'fontColor': '#45A5F5',
                'fontSize': 14
              },
              'gridLines': {
                'drawOnChartArea': false // only want the grid lines for one axis to show up
              }
            }
          ],
          'xAxes': [{
            // 'display': true,
            'stacked': true,
            'barThickness': 10, // Grosor barra
            'ticks': {
              'display': true,
              'fontColor': '#232323',
              'fontSize': 12
            }
          }]
        }
      }
    }
  },
  mounted () {
    // console.log('--', this.datasets)
    this.renderChart(this.datasets, this.options)
  },
  computed: {
    datasets () {
      return {
        'labels': this.valuesData.labels,
        'datasets': [
          {
            'yAxisID': 'y-left',
            'type': 'line',
            'lineTension': 0,
            'pointStyle': 'rect', // tipo rectangulo de punto
            'pointRadius': 5,
            'label': '',
            'borderColor': '#2F5EC4',
            'pointBackgroundColor': '#2F5EC4',
            'borderWidth': 2,
            'fill': false,
            'data': this.valuesData.dataOne
          },
          {
            'yAxisID': 'y-right',
            'type': 'bar',
            'label': '',
            'backgroundColor': '#45A5F5',
            'borderColor': 'white',
            'borderWidth': 2,
            'fill': false,
            'data': this.valuesData.dataTwo
          }
        ]
      }
    }
  },
  methods: {
  },
  watch: {
    valuesData () {
      // console.log(`change---> ${this.valuesData.dataOne[0]} , ${this.datasets.datasets[0].data[0]}`)
      this._data._chart.destroy()
      this.renderChart(this.datasets, this.options)
    }
  }
}
