<template lang="pug">
  #catalog-filterTop
    .contanier.tw-block
      .row(class="tw-flex tw-items-center")
        .col-md-5
          div(v-if="aFilterByType && numFilterByType > 1" class="tw-text-left")
            span(v-for="(value, key) in aFilterByType" @click="changeFilterByType(key)" 
            class="tw-rounded-[4px] tw-bg-[#E1EDFE] tw-p-[8px] tw-mr-[8px] tw-text-[13px] tw-cursor-pointer" 
            :class="{'!tw-bg-[#9ce16d] tw-text-white': key=== 'is_eco' && !catalogueOptions.filterByType.includes(key), '!tw-bg-[#1C2C40] tw-text-white': catalogueOptions.filterByType.includes(key) || (key === 'all' && catalogueOptions.filterByType.length === 0)}")
              span(:value="key" ) {{value}}
        .col-md-7.tw-text-right
          .combo-common(v-if="aFilterProductList && !isPageCatalogoPublic && numFilterProductList > 1")
            label #[translate Filtrar]:
            .dropdown.Select-bk
              button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddAutorizado" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{filterProductItem}}
                i(class="fa fa-angle-down" aria-hidden="true")
              ul(class="dropdown-menu" aria-labelledby="ddAutorizado")
                li(v-for="(value, key) in aFilterProductList" :class="{'disabled': filterProductItem == value}")
                  span(:value="key" @click="changeTypeProduct(key,value)") {{value}}

          .combo-common
            label #[translate Ordenar]:
            .dropdown.Select-bk
              button.tw-bg-white.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{order}}
                i(class="fa fa-angle-down" aria-hidden="true")
              ul(class="dropdown-menu" aria-labelledby="ddOrder")
                li(v-for="(value, key) in aOrder" :class="{'disabled': order == value}")
                  span(:value="key" @click="changeOrderProduct(key,value)") {{value}}
          // p.view-cn
              span
                i(class="fa fa-th-large" aria-hidden="true" :class="{'active': view=='grid'}" @click="changeView('grid')")
                i(class="fa fa-th-list" aria-hidden="true" :class="{'active': view=='list'}" @click="changeView('list')")
</template>
<script>
import {mapGetters} from 'vuex'
import * as types from '@/store/mutation-types'

export default {
  name: 'CatalogFilterTop',
  props: ['view'],
  data () {
    return {
      filterProductItem: '',
      numFilterProductList: 0, // todos o autorizados.
      urlEco: false,
      urlCovid: false,
      numFilterByType: 0, // Más consumidos, outlet y eco.
      order: this.$gettext('Por nombre (de la A a la Z)'),
      aOrderElastic: {
        'elastic_relevance': this.$gettext('Relevancia')
      },
      aOrderDefault: {
        // 'name_desc': this.$gettext('Alfabéticamente'),
        'name': this.$gettext('Por nombre (de la A a la Z)'),
        'family__name': this.$gettext('Por familia')
      },
      aOrderConsumo: {
        'consumption_asc': this.$gettext('Consumo (de menor a mayor)'),
        'consumption_desc': this.$gettext('Consumo (de mayor a menor)')
      },
      aOrderPrice: {
        'price_asc': this.$gettext('Precio (de menor a mayor)'),
        'price_desc': this.$gettext('Precio (de mayor a menor)')
      }
    }
  },
  created () {
    // do something after creating vue instance
    if (this.$route.query.products === 'eco') {
      this.urlEco = true
    } else if (this.$route.query.products === 'covid') {
      this.urlCovid = true
    }
    if (this.isPageCatalogoPublic) {
      this.order = this.$gettext('Más consumidos')
    }

    if (this.center) {
      this.orderConsumoDesc()
    }
  },
  computed: {
    ...mapGetters([
      'role', 'isLoggedIn'
    ]),
    catalogueOptions () {
      return this.$store.getters.catalogueOptions
    },
    center () {
      return this.$store.state.center.center
    },
    client () {
      return this.$store.state.client.client
    },
    user () {
      return this.$store.getters.user
    },
    aOrder () {
      let aOrder = this.aOrderDefault
      if (this.center && !this.isPageCatalogoPublic) {
        aOrder = {...this.aOrderDefault, ...this.aOrderPrice, ...this.aOrderConsumo}
      }

      if (
        this.config_app.elastic_search &&
        (
          this.$route.name === 'catalogoSearch' ||
          this.$route.name === 'leadSearch' ||
          this.$route.name === 'catalogoPublicSearch'
        )
      ) {
        aOrder = {...this.aOrderElastic, ...aOrder}
      }

      return aOrder
    },
    aFilterProductList () {
      // filtro ver todos los productos o los autorizados.
      if (!this.client) {
        return null
      }
      let obj = {}
      let flagShowAllorAuthorized = false
      if ((this.client && this.client.only_authorized_products) || (this.user && this.user.only_authorized_products)) {
        flagShowAllorAuthorized = true
      }
      if (!flagShowAllorAuthorized && this.client.has_special_prices) {
        obj.special_price = this.$gettext('Autorizados')
      }

      // console.log('obj', obj)
      if (!flagShowAllorAuthorized && this.client.has_special_prices) {
        obj.all = this.$gettext('Todos')
        this.filterProductItem = Object.values(obj)[1]
        //this.changeTypeProduct(Object.keys(obj)[1], Object.values(obj)[1])
      }

      this.numFilterProductList = Object.keys(obj).length

      return obj
    },
    aFilterByType () {
      let obj = {}

      obj.all = this.$gettext('Todos')
      if (this.client && this.client.has_usual_products && !this.isPageCatalogoPublic) {
        obj.only_usual_products = this.$gettext('Comprados')
      }

      if (this.config_app.is_outlet) {
        obj.is_outlet = this.$gettext('Outlet')
      }

      if (this.config_app.is_eco) {
        obj.is_eco = this.$gettext('Eco')
      }
      if (this.config_app.is_covid) {
        obj.is_covid = this.$gettext('Covid')
      }
      if (this.catalogueOptions.filterByType.length === 0) {
        if (this.client && this.client.has_usual_products && !this.isPageCatalogoPublic) {
          if (!this.catalogueOptions.filterByType.includes(Object.keys(obj)[1])) {
            this.catalogueOptions.filterByType.push(Object.keys(obj)[1])
            this.$store.dispatch('setCatalogueOptions', this.catalogueOptions)
          }        
        } else {
          if (!this.catalogueOptions.filterByType.includes(Object.keys(obj)[0])) {
            this.catalogueOptions.filterByType.push(Object.keys(obj)[0])
            this.$store.dispatch('setCatalogueOptions', this.catalogueOptions)
          }   
        }
      }
      this.numFilterByType = Object.keys(obj).length
      return obj
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    type_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.type
    },
    isPageCatalogoPublic () {
      if (
        this.config_app.catalogo_public &&
        (
          this.$route.name === 'catalogoListPublic' ||
          this.$route.name === 'productSinglePublic' ||
          this.$route.name === 'catalogoPublicSearch'
        )
      ) {
        return true
      }

      return false
    }
  },
  mounted () {
    // console.log('mounted')
    this.orderFilterViewProductsState()
  },
  methods: {
    orderFilterViewProductsState () {
      // miramos en la store las opciones de 'ordenar por' y 'ver producto' para aplicarlo o no.
      if (this.$route.query.q) {
        // Si volvemos a la pag de búsqueda.
        this.orderRelevancia()
      } else if (this.catalogueOptions.ordering) {
        // si existe un orden lo aplicamos.
        this.changeOrderProductManual(this.catalogueOptions.ordering)
      } else {
        this.changeOrderProductManual('')
      }
      // todos y autorizados
      if (this.catalogueOptions.filterViewProducts) {
        // console.log('Si existe en la store "ver producto" lo aplicamos.')
        this.changeTypeProductManual(this.catalogueOptions.filterViewProducts)
      } else {
        // console.log('No existe en la store "ver producto" lo aplicamos.')
        if (this.aFilterProductList && Object.keys(this.aFilterProductList).length > 0) {
          this.changeTypeProduct(Object.keys(this.aFilterProductList)[0], Object.values(this.aFilterProductList)[0])
        }
      }

      // mas consumo, outlet y eco.
      // console.log('------filterByType:', this.catalogueOptions.filterByType)
      if (this.catalogueOptions.filterByType) {
        // console.log('Si existe en la store "filterByType" lo aplicamos.')
        
      } else {
        // console.log('No existe en la store "filterByType" lo aplicamos.')
        if (this.aFilterByType && Object.keys(this.aFilterByType).length > 0) {
          // si es PEDROSA y tiene el query param ?products=eco cambiamos el filtro automaticamente a "eco"
          if (this.urlEco) {
            this.changeFilterByType('is_eco')
          } else if (this.urlCovid) {
            this.changeFilterByType('is_covid')
          } else {
            this.changeFilterByType(Object.keys(this.aFilterByType)[0])
          }
        }
      }
    },
    changeView (v) {
      // Cambia la vista del grid de productos
      if (this.view !== v) {
        this.$emit('toChangeView', [v])
      }
    },
    changeFilterByType (key) {
      // Modificamos el ver productos
      if (key === 'all') {
        this.catalogueOptions.filterByType = []
        this.catalogueOptions.filterByType.push('all')
      } else {
        if (this.catalogueOptions.filterByType.includes('all')) {
          this.catalogueOptions.filterByType = this.catalogueOptions.filterByType.filter(e => e != 'all')
        }
        if (!this.catalogueOptions.filterByType.includes(key)) {
          this.catalogueOptions.filterByType.push(key)
        } else {
          this.catalogueOptions.filterByType = this.catalogueOptions.filterByType.filter(e => e != key)
        }
      }
      // enviamos la actualización a la store.
      this.$store.dispatch('setCatalogueOptions', this.catalogueOptions)
    },
    changeTypeProduct (key, value) {
      // console.log(`Filter-top ---> changeTypeProduct: k: ${key}, v: ${value}`)
      // Modificamos el ver productos
      this.catalogueOptions.filterViewProducts = key
      // enviamos la actualización a la store.
      this.$store.dispatch('setCatalogueOptions', this.catalogueOptions)

      this.filterProductItem = value
      this.$emit('toChangeFilterProduct', [key])
    },
    changeTypeProductManual (key) {
      let value = null

      if (this.aFilterProductList && key) {
        value = this.aFilterProductList[key]
      } else if (this.aFilterProductList && Object.keys(this.aFilterProductList).length > 0) {
        key = Object.keys(this.aFilterProductList)[0]
        value = Object.values(this.aFilterProductList)[0]
        // console.log(`No key - changeTypeProductManual ---> k: ${key}, v: ${value}, client: `, this.client)
      }

      // console.log(`-------changeTypeProductManual ---> k: ${key}, v: ${value}`)
      if (key && value) {
        this.changeTypeProduct(key, value)
      }
    },
    changeOrderProduct (key, value) {
      // console.log(`changeOrderProduct -> key: ${key}, value: ${value} `)
      // Modificamos el orden
      this.catalogueOptions.ordering = key
      // enviamos la actualización a la store.
      this.$store.dispatch('setCatalogueOptions', this.catalogueOptions)
      this.order = value
      this.$emit('toChangeOrdering', [key])
    },
    changeOrderProductManual (key) {
      let value = null

      if (key) {
        value = this.aOrder[key]
      }

      if (!key || !value) {
        key = 'family__name'
        value = this.aOrder[key]
        if (this.type_app === 'pedrosa' && (this.isPageCatalogoPublic || !this.center)) {
          key = 'family__name'
          value = this.aOrder[key]
        }
      }

      this.changeOrderProduct(key, value)
    },
    orderConsumoDesc () {
      this.changeOrderProductManual('consumption_desc')
    },
    orderRelevancia () {
      this.changeOrderProductManual('elastic_relevance')
    }
  },
  watch: {
    '$route' (to, from) {
      // react to route changes...
      // console.log(`watch router -> to ${to.name}, from ${from.name}`)
      if (
        (
          to.name === 'catalogoSearch' ||
          to.name === 'leadSearch' ||
          to.name === 'catalogoPublicSearch'
        ) &&
        this.$route.query.q
      ) {
        // Si volvemos a la pag de búsqueda.
        this.orderRelevancia()
      } else if (to.name === 'catalogoList' || to.name === 'leadOferta' || to.name === 'catalogoListPublic') {
        // console.log('watch list, aOrder: ', this.aOrder)
        // console.log('watch list, catalogueOptions: ', this.catalogueOptions)
        /* this.order = Object.values(this.aOrder)[0]
        this.catalogueOptions.ordering = Object.keys(this.aOrder)[0]
        this.$store.commit(types.UPDATE_CATALOGUE_OPTIONS, this.catalogueOptions) */
        this.orderFilterViewProductsState()
      }
    },
    center () {
      // console.log(`---ordering: ${this.catalogueOptions.ordering}, center:,` + this.center)
      if (!this.center) {
        this.changeOrderProductManual('family__name')
      } else if (this.order !== this.$gettext('Consumo (de mayor a menor)') && this.center) {
        this.orderConsumoDesc()
      }
    },
    /*catalogueOptions (newVal, oldVal) {

      console.log('entra')
      if (newVal.filterViewProducts !== '') {
        this.filterProductItem = this.aFilterProductList[newVal.filterViewProducts]
      }
    }*/
  }
}
</script>
<style lang="scss" scoped>
  #catalog-filterTop{
    text-align: right;
    margin-bottom: 12px;

    & > *{
      margin-bottom:0;
      vertical-align: middle;

      &:not(:last-child){
        margin-right: 24px;
      }
    }
  }

  label{
    color: #494f58;
    font-size: 14px;
    font-weight: $regular;
    line-height: 38px;
  }

  .view-cn{
    display: none!important;
    i{
      color:#c4cdd3;
      @include transition();
      font-size:24px;
      cursor:pointer;

      &.active{
        color:$blue-light;
      }

      &:last-child{
        margin-left:18px;
      }
    }
  }

  .dropdown{
    display:inline-block;
    vertical-align: top;
    margin-left:12px;
    min-width: 190px;

    li, button{
      color: #494f58;
      font-size: 14px;
    }

    .dropdown-toggle{
      height: 38px;
      line-height: 38px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 50px;

      i{
        width:40px;
        line-height: 36px;
        background-color: transparent;
        color: $blue-light;
      }
    }
  }
</style>
