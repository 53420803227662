<template>
  <modal :show.sync="syncShow" max-width-class="tw-max-w-[730px]">
    <template #header="{ close }">
      <div class="tw-flex justify-between tw-items-center tw-py-4 tw-px-5 tw-rounded-t tw-border-b-4">
        <h1 class="tw-text-2xl">Error</h1>
        <button @mousedown="close" type="button" class="tw-right-2 tw-text-gray-400 hover:tw-bg-[#efeded] hover:tw-text-gray-900 tw-rounded-lg tw-text-sm tw-p-1.5 tw-ml-auto tw-inline-flex tw-items-center" data-modal-toggle="defaultModal">
          <img src="./close-modal-icon.svg" alt="Close">
        </button>
      </div>
    </template>

    <template #body>
      <div class="tw-px-5 tw-py-4 tw-space-y-4">
        <p class="tw-text-[14px] tw-leading-relaxed tw-text-[#43425D]">Por favor seleccione un rango de fechas que esté dentro del mismo año.</p>
      </div>
    </template>

    <template #footer="{ close }">
      <div></div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/common/Modal_v2.vue'
import RangeInput from "@/components/finger-print/RangeInput";
export default {
  name: "DatePickerAlert",
  components: { RangeInput, Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      syncShow: false
    }
  },
  watch: {
    show(state) {
      this.syncShow = state
    },
    syncShow(state) {
      this.$emit('update:show', state)
    }
  }
}
</script>

<style></style>
