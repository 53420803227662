<template lang="pug">
  .pedidos-cn
    // Mostramos listado
    div(v-if="$route.name !== 'catalogGeneratorSingle'")
      .box-2
        .container
          .row
            .col-sm-8
              bk-search(@toSearch="toSearch")
            .col-sm-4
      .box-3
        .container
          .row
            .col-sm-8
              p.info-cn #[translate Catálogos]
                small(:class="{'fade-in': catalogsNum}")  ({{catalogsNum}} #[translate Catálogos])

      bk-grid(
        :catalogs="aCatalogs"
        :message="message"
        :pages="totalPages"
        :count="catalogsNum"
        :currentPage="currentPage"
        @toPaginate="pagChange")

    // Mostramos el detalle del pedido.
    div(v-if="$route.name == 'catalogGeneratorSingle'")
      transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
        router-view
</template>
<script>
// import appConfig from '@/config-app.js'
import BkSearch from '@/components/catalogpdf/Search.vue'
import BkGrid from '@/components/catalogpdf/Listado.vue'
// importamos servicios Catálogo
import SrvCatalogGenerator from '@/services/cataloggenerator'

export default {
  name: 'CatalogGeneratorList',
  props: [],
  components: {BkSearch, BkGrid},
  data () {
    return {
      aCatalogs: '',
      catalogsNum: null,
      message: '',
      totalPages: 0,
      init: false, // Para comprobar si es la primera vez que llamamos a la API
      currentPage: 0, // Para saber porque página vamos en la paginación
      query: '' // Donde se guarla la palabra a buscar.
    }
  },
  created () {
    this.query = ''
    this.currentPage = 1
    this.search()
  },
  computed: {
    type_app () {
      return this.$config.type
    },
    country () {
      return this.$store.getters.userInfo.country
    },
    center () {
      return this.$store.getters.center
    },
    client () {
      return this.$store.getters.client
    }
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.currentPage = pageNum
      this.search()
    },
    toSearch (query) {
      this.query = query
      this.currentPage = 1
      this.search()
    },
    search () {
      window.scrollTo(0, 0) // movemos el scroll arriba
      SrvCatalogGenerator.getCatalogsGenerator(this.query, this.currentPage)
        .then(res => {
          if (res.status === 200) {
            this.aCatalogs = res.data.results
            this.catalogsNum = res.data.count
            this.totalPages = res.data.total_pages
            // console.log(`ordersNum: ${this.ordersNum}`)
            // console.log('order:', res.data.results[0])
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    }
  },
  watch: {
    country () {
      // actualizamos el valor de los pedidos pendientes.
      this.currentPage = 1 // Inicializamos la páginación
      this.search()
    },
    center () {
      // console.log('cambio center:', this.center)
      this.currentPage = 1 // Inicializamos la páginación
      this.search()
    },
    client () {
      // console.log('----cambio cliente:', this.client)
      this.currentPage = 1 // Inicializamos la páginación
      this.search()
    }
  }
}
</script>
<style lang="scss" scoped>

  .box-2{
    padding: 14px 0;
    background-color: #EFEFEF;
  }

  .box-3{
    padding: 26px 0 20px;

    .row{
      display: flex;
      align-items: center;
    }

    .info-cn{
      font-size: 16px;
      margin: 0;

      small{
        font-size: 16px;
      }
    }
  }

</style>
