/*
  ref:
  https://stackoverflow.com/questions/38724876/how-to-add-text-in-centre-of-the-doughnut-chart-using-chart-js
  https://stackoverflow.com/questions/20966817/how-to-add-text-inside-the-doughnut-chart-using-chart-js
  https://gist.github.com/mesuutt/9d72f0e365fa08806b31790e0a05e5b4
  https://stackoverflow.com/questions/42759306/add-text-inside-doughnut-chart-from-chart-js-2-in-react
*/
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['valuesData'],
  data () {
    return {
      'options': {
        'responsive': true,
        'maintainAspectRatio': false,
        'cutoutPercentage': 60, // Grosor
        'legend': {
          'display': false // Oculta el Label del Dataset
        },
        'tooltips': {
          'enabled': false
        },
        'hover': {
          'animationDuration': 1
        },
        'animation': {
          // 'animateScale': true,
          'animateRotate': true,
          'onComplete': function () {
          }
        },
        'scales': {
          'yAxes': [{
            'display': false
          }],
          'xAxes': [{
            'display': false
          }]
        }
      }
    }
  },
  mounted () {
    // console.log('--', this.datasets)
    this.renderChart(this.datasets, this.options)
  },
  computed: {
    datasets () {
      return {
        'labels': ['pp'],
        'datasets': [
          {
            'backgroundColor': [this.valuesData.color, '#e6eaee'],
            'data': [this.valuesData.value, this.getDiffPorcent()]
          }
        ]
      }
    }
  },
  methods: {
    getDiffPorcent () {
      let value = this.valuesData.value
      value = (value > 100) ? 0 : 100 - this.valuesData.value
      // console.log(`Donut valueData: ${this.valuesData.value}, result value: ${value}`)
      return value
    }
  },
  watch: {
    valuesData () {
      // console.log(`change---> ${this.valuesData.dataOne[0]} , ${this.datasets.datasets[0].data[0]}`)
      this._data._chart.destroy()
      this.renderChart(this.datasets, this.options)
    }
  }
}
