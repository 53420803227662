<template lang="pug">
  #perfil
    .box-1
      .container
        .row
          .col-sm-12
            h1
              img(:src="img.config" width="20")
              translate Configuración
    .container.box-3(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}

    .container.box-2(v-if="!message")
      .row
        .col-sm-10.c-c
          h2 #[translate Mi cuenta]
      .row(v-if="okMsgShow")
        .col-sm-10.c-c
          div.alert.alert-success.fade.in #[translate Datos actualizados].
      .row(v-if="errorMsgShow")
        .col-sm-10.c-c
          div.alert.alert-danger.fade.in #[translate ¡Error! datos incorrectos].
      .row
        .col-sm-8.c-c
          form(@submit.prevent="validate" @keyup.enter="validate")

            .row
              .col-sm-6
                label #[translate Correo electrónico]
                input.form-control(type="text" v-model="user.email" :placeholder="placeholder.email" :class="{ 'error':errorEmail }" @focus="resetError")
              // .col-sm-6
                label #[translate Contraseña]
                input.form-control(type="password" v-model="user.pwd" :placeholder="placeholder.pwd" :class="{ 'error':errorPwd }" @focus="resetError")

            .row
              .col-sm-6
                label #[translate Nombre]
                input.form-control(type="text" v-model="user.first_name" :placeholder="placeholder.nombre" :class="{ 'error':errorNombre }" @focus="resetError")
              .col-sm-6
                label #[translate Apellidos]
                input.form-control(type="text" v-model="user.last_name" :placeholder="placeholder.apellidos" :class="{ 'error':errorApellido }" @focus="resetError")
            .row.submit-cn
              .col-sm-6.text-right
                button.btn.btn-cancel( type="button" @click="goBack()") #[translate Cancelar]
              .col-sm-6
                button.btn.btn-action #[translate Actualizar]
</template>
<script>
// importamos servicios Users
import SrvUsers from '@/services/users'

export default {
  name: 'Perfil',
  data () {
    return {
      user: {'email': ''},
      errorEmail: false,
      errorPwd: false,
      errorNombre: false,
      errorApellido: false,
      errorMsgShow: false,
      okMsgShow: false,
      errorMsg: false,
      message: false,
      placeholder: {
        'email': this.$gettext('Correo electrónico'),
        'pwd': this.$gettext('Contraseña'),
        'nombre': this.$gettext('Nombre'),
        'apellidos': this.$gettext('Apellidos'),
        'organizacion': this.$gettext('Organización'),
        'movil': this.$gettext('Teléfono móvil'),
        'telefono': this.$gettext('Teléfono fijo')
      },
      img: {
        config: require('@/assets/img/icos/config.svg')
      }
    }
  },
  created () {
    // console.log(this.$store.state.user.user)
    SrvUsers.getDetails()
      .then(res => {
        // console.log('---> User: ', res.data)
        this.user = res.data
      }, () => {
        // console.log('----->' + error)
        this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
      })
  },
  methods: {
    validate () {
      const email = this.user.email
      // const pwd = this.user.pwd
      const nombre = this.user.first_name
      const apellido = this.user.last_name
      this.resetError()

      let RegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/
      if (email === null || email === '' || !email.match(RegEx)) {
        this.errorMsg = true
        this.errorEmail = true
      }

      /* if (pwd === null || pwd === '') {
        this.errorMsg = true
        this.errorPwd = true
      } */

      if (nombre === null || nombre === '') {
        this.errorMsg = true
        this.errorNombre = true
      }

      if (apellido === null || apellido === '') {
        this.errorMsg = true
        this.errorApellido = true
      }

      if (!this.errorMsg) {
        SrvUsers.updateDetails(this.user)
          .then(res => {
            // console.log('---> User actualizado: ', res.data)
            // Mostramos el mensaje de datos actualizados
            // this.okMsgShow = true

            // Enviamos al usuario al login para que se vuelva a logar, ya que cambio los datos.
            this.$router.push('/')
          }, () => {
            // console.log('----->' + error)
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          })
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    resetError () {
      this.errorMsgShow = false
      this.errorEmail = false
      this.errorNombre = false
      this.errorApellido = false
      this.okMsgShow = false
      this.errorMsg = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .box-1{
    position: relative;
    margin-top: 40px;
    background-color: $blue-bg-light;

    &:before{
      content:'';
      position: absolute;
      top: -40px;
      left:0;
      width: 100%;
      height: 40px;
      background-color: rgba($grey-bg, .33);
    }
  }
  h1{
    font-weight: $regular;
    font-size:16px;
    padding: 20px 0;
    margin: 0;

    img{
      margin-right: 14px;
      vertical-align: text-bottom;
    }
  }

  h2{
    font-size: 16px;
    font-weight: $regular;
    margin-top: 44px;
  }

  .c-c{
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .box-2{

    .alert{
      margin: 10px 0;
    }
  }

  form{
    margin:20px auto 26px;
    max-width: $width-page;

    .row + .row{
      margin-top: 12px;
    }

    .btn{
      width:100%;
      max-width: 316px;
      font-weight: $regular;
      text-transform: none;
    }
  }

  label{
    display:block;
    color:#3c3e4b;
    font-size:13px;
    margin-bottom:12px;
    font-weight:$regular;
  }

  input[type="text"]{
    height: 40px;
    font-size: 12px;
    font-weight: $light;
  }

  .submit-cn{
    margin-top:30px!important;
    padding-top:34px;
    border-top:2px solid $grey-bg;
  }

  .alert-warning{
    margin-top: 40px;
  }
</style>
