<template lang="pug">
  #CenterSearch
    form(@submit.prevent="toSearch" @keyup.enter="toSearch" :class="{'type-modal': type == 'modal' }")
      img(:src="img.icoClient" width="22")
      label {{typeSearchName}} &nbsp;
        span(v-if="count") ({{count}} {{typeSearchName}})
      .input-group
        input(ref="search" id="product-search-query" class="form-control" type="search" v-model="q" minlength="3" :placeholder="placeholder.search" value="")
        span.input-group-btn
          button.btn.btn-action(type="submit")
            img(:src="img.lupa" width="16" title="Search")
</template>
<script>
export default {
  name: 'CenterSearch',
  props: ['count', 'type', 'typeSearch'],
  data () {
    return {
      q: null,
      placeholder: {
        'search': this.$gettext('Buscar ....')
      },
      img: {
        icoClient: require('@/assets/img/icos/cliente_2.svg'),
        lupa: require('@/assets/img/icos/buscar.svg')
      }
    }
  },
  computed: {
    typeSearchName () {
      return (this.typeSearch === 2) ? this.$gettext('Leads') : this.$gettext('Clientes')
    }
  },
  methods: {
    toSearch () {
      let query = this.q

      if (query !== null && query !== '' && query.length > 2) {
        // console.log(`to search: ${query},  ${query.length}`)
        this.q = ''
        this.$emit('toSearch', [query])
      }
    }
  }
}
</script>
<style lang="scss" scoped>

  label{
    font-weight: $regular;
    font-size:14px;
    color: #1C2C40;
    padding:0 30px 0 10px;

  }

  .input-group{
    max-width: 780px;
    display: inline-table;
    vertical-align: middle;
  }

  .btn-action{
    border-color: $white;
    padding: 11px 20px;
    img{
      max-width: none;
    }
  }

  #product-search-query{
    line-height: 40px;
    height: 46px;
  }

  .type-modal{
    .input-group{
      max-width: 626px;
    }
  }


</style>
