import { render, staticRenderFns } from "./Grid-ventas-perdidos.vue?vue&type=template&id=5393761f&scoped=true&lang=pug"
import script from "./Grid-ventas-perdidos.vue?vue&type=script&lang=js"
export * from "./Grid-ventas-perdidos.vue?vue&type=script&lang=js"
import style0 from "./Grid-ventas-perdidos.vue?vue&type=style&index=0&id=5393761f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5393761f",
  null
  
)

export default component.exports