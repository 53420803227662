<template lang="pug">
  div
    bk-cargos(v-for="(item, index) in cargos" :key="index" :id="index" :item="item" :maxDiscount="maxDiscount" @toSetCargo="toSetCargo" )

</template>
<script>
import BkCargos from '@/components/catalog/Cargos-item.vue'

export default {
  name: 'CargosList',
  components: { BkCargos },
  props: ['cargos', 'maxDiscount'],
  data () {
    return {
    }
  },
  methods: {
    toSetCargo (cargo) {
      this.$emit('toSetCargo', cargo)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
