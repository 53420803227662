<template lang="pug">
  #dashboard
    .container.box-2
      .row
        .col-sm-2.sidebar-left
          bk-sidebar-left( :aTree="aTree" @toTreeId="toTreeId" )
        .col-sm-10.content-cn
          transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
            router-view
</template>

<script>
import BkSidebarLeft from '@/components/dashboard/Sidebar-left.vue'
// importamos servicios Catálogo
import SrvDashboard from '@/services/dashboard'

export default {
  name: 'Dashboard',
  components: { BkSidebarLeft },
  data () {
    return {
      aTree: [],
      idTree: 0
    }
  },
  created () {
    this.getNodeTree()
  },
  methods: {
    getNodeTree () {
      // Generamos el menu dle sidebar izquierdo
      SrvDashboard.getNodeTree()
        .then(res => {
          if (res.status === 200 && res.data.length) {
            this.aTree = res.data
            this.toTreeId(this.aTree[0]) // Cogemos el primer ID del aTree para cargar su resumen.
            // console.log(`Generamos el menú izquierdo y cogemos el primer ID (${this.aTree[0]}) para cargar el resumen.`)
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    toTreeId (item) {
      // console.log(`------item.id : ${item.id}, idtree: ${this.idTree}`)

      let dashboard = this.$store.getters.dashboard

      if (dashboard === null && this.idTree === 0) {
        // es la primera vez que entra al Dashboard
        this.migas = item.node_name
        this.idTree = item.id
        // Enviamos a Store Dashboard los datos del item seleccionado.
        this.$store.dispatch('setDashboard', item)
        // Lo pasamos a su State
        this.$store.commit('SET_DASHBOARD')
        // console.log(`dashboard = null ------> item.id : ${item.id}, idtree: ${this.idTree}`)
      } else if (this.idTree !== item.id && this.idTree !== 0) {
        this.migas = item.node_name
        this.idTree = item.id
        // Enviamos a Store Dashboard los datos del item seleccionado.
        this.$store.dispatch('setDashboard', item)
        // Lo pasamos a su State
        this.$store.commit('SET_DASHBOARD')
      } else if (dashboard !== null && this.idTree === 0) {
        this.idTree = dashboard.id
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  #dashboard{
    border-top: 4px solid #F1F1F1;
    margin-bottom: -40px;
  }

  .sidebar-left{
    padding-top: 20px;
  }
  .content-cn{
    border-left: 1px solid #eff3f6;
    padding: 24px;
    background-color: #FAFAFA;
    min-height: 1000px;
  }
</style>
