<template lang="pug">
  #modalShipping(class="modal fade" :class="classOpen" role="dialog")
    .modal-dialog.modal-lg
      .modal-content
        .modal-header
          button(type="button" class="close" data-dismiss="modal")
            img(:src="img.close" width="12")
          p
            img(:src="img.camion" width="26")
            translate SHIPPING
        .modal-body
          .container
            .row.type-head
              .col-sm-10 #[translate Shipping COST]
              .col-sm-2.text-right #[translate Cost]
            .row.type-item(v-for="(item, index) in items" :key="item.id")
              .col-sm-10 {{item.label}}
              .col-sm-2.text-right {{item.cost | format-money}} {{moneda}}

</template>
<script>

export default {
  name: 'ShippingModal',
  components: {},
  props: ['classOpen'], // clase para abrir el popup
  data: () => ({
    // TODO: poner los datos de la API de David.
    'items': [
      {
        'id': 1,
        'label': 'Peninsular Spain, Mallorca, Menorca, Ibiza and Formentera, Canary Islands (Tenerife, Gran Canaria)  European Union',
        'cost': 5.90
      },
      {
        'id': 2,
        'label': 'Canary Islands (Lanzarote, Fuerteventura, La Palma, Hierro and Gomera)',
        'cost': 5.90
      },
      {
        'id': 3,
        'label': 'Portugal Islands (Azores and Madeira)',
        'cost': 5.90
      }
    ],
    'img': {
      'close': require('@/assets/img/icos/cerrar-01.svg'),
      'camion': require('@/assets/img/icos/camion.svg')
    }
  }),
  computed: {
    cart () {
      return this.$store.getters.cart
    },
    moneda () {
      return this.cart.moneda
    }
  },
  created () {
  },
  methods: {
    openModal () {

    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-content{
    border: 1px solid #17296E;
    @include roundAll(5);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    box-shadow: 0 3px 6px rgba(0,0,0,.25);
    overflow: hidden;
  }
  .modal-header{
    background-color: #EBF3FE;
    padding-top:22px;
    padding-bottom: 22px;

    p{
      margin:0;
      img{
        margin-right: 24px;
      }
    }

    .close{
      margin-top: -22px;
      font-size: 40px;
      opacity: 1;
    }
  }

  .modal-body{
    padding: 0 0 28px;

    .row{
      &:nth-child(even) {
          background-color: #F9FAFB;
      }
    }
  }

  .type-head, .type-item{
      padding: 14px 0;

  }
  .type-head{
    border-bottom: 1px solid #17296E;
  }

  .type-item{

  }
</style>
