<template lang="pug">
  #dashboardVentaPerdidosListItem.container
    .row.box-1
      .col-sm-5
        p.name {{client.name}}
      .col-sm-2
        p.text-center {{client.year_sales | format-money}}
      .col-sm-2
        p.text-center {{client.real_sales_target | format-money}}
      .col-sm-2
        p.text-center {{client.previous_year_sales | format-money}}
      .col-sm-1
        p.text-center
          img(:src="img.ver" class="lupa" width="18" alt="" @click="goRankingCenter()")
</template>
<script>
export default {
  name: 'DashboardVentaPerdidosListItem',
  props: ['client', 'index', 'type'],
  data () {
    return {
      'img': {
        'ver': require('@/assets/img/icos/ver_ranking.svg')
      }
    }
  },
  methods: {
    goRankingCenter () {
      // console.log(`goRakingcenter - client ID: ${this.client.id}`)
      this.$router.push({name: 'dashboardVentasPerdidosCenter', params: {id: this.client.id}})
    }
  },
  computed: {
    type_app () {
      return this.$config.type
    }
  }
}
</script>
<style lang="scss" scoped>
  .box-1{
    display: flex;
    align-items: center;
    padding:8px 0;
    color: #4D4F5C;
    font-size: 14px;
  }

  p{
    position: relative;
    margin: 0;

    small{
      display: block;
      color: #71c061;
      font-size: 14px;

      &.menos-cn, .menos-cn{
        color: #f95d5d;
      }
    }
  }

  .lupa{
    cursor: pointer;
    @include transition();

    &:hover, &:focus{
      opacity: .8
    }
  }
</style>
