<template lang="pug">
  #CatalogSearch
    form(@submit.prevent="setBtnSearch" @keyup.enter="setBtnSearch")
      .input-group
        .dropdown
          button.tw-bg-white(class="btn btn-default dropdown-toggle" type="button" id="dd-search-cat" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{catCurrrent.name}}
            i(class="fa fa-angle-down" aria-hidden="true")
          ul(class="dropdown-menu" aria-labelledby="dd-search-cat")
            li( :class="{'disabled': catCurrrent.name == catTodas.name}")
              span( @click="changeCat(catTodas)") {{catTodas.name}}
            li(v-for="item in catSelect" :class="{'disabled': catCurrrent.name == item.name}")
              span( @click="changeCat(item)") {{item.name}}
        input(id="product-search-query" class="form-control form-control-lg" type="search" v-model="q" :placeholder="placeholderPedrosa.search" value="" @search="removeSearch()")
        span.input-group-btn
          button.btn.btn-action(type="submit")
            i.fa.fa-search(title="Search")
        span(class="hidden") #[translate xxx#/]
</template>
<script>
export default {
  name: 'CatalogSearch',
  props: ['categories', 'category', 'type'],
  data () {
    return {
      q: null,
      catCurrrent: {'id': '', 'name': this.$gettext('Todas las categorías')},
      catTodas: {'id': '', 'name': this.$gettext('Todas las categorías')},
      catRouter: null, // id de la categoría que viene por url
      placeholder: {
        'search': this.$gettext('Buscar Productos: Nombre, Código de producto, ...')
      },
      placeholderPedrosa: {
        'search': this.$gettext('Buscar Productos: Nombre, Referencia, ...')
      }
    }
  },
  computed: {
    type_app () {
      return this.$config.type
    },
    catSelect () {
      if (this.categories[0] && this.categories[0].children) {
        return this.categories[0].children
      }
      return this.categories
    }
  },
  mounted () {
    this.setUrlSearch()
  },
  methods: {
    changeCat (v) {
      // console.log(`changeCat -> id: ${v.id}, name: ${v.name}`)
      this.catCurrrent = v
    },
    toSearch () {
      // console.log(`toSearch : ${this.q}, ${this.catCurrrent.id}`)
      this.$emit('toSearchCategory', [this.q, this.catCurrrent.id])
    },
    setUrlSearch () {
      // console.log(`setUrlSearch -> q: ${this.$route.query.q}`)
      if (this.$route.query.q) {
        // si viene una busqueda.
        this.q = this.$route.query.q
      } else {
        this.q = ''
      }

      // si viene una categoría en la búsqueda.
      if (this.$route.query.cat) {
        // si viene una busqueda.
        this.catRouter = this.$route.query.cat
      } else {
        this.catCurrrent = this.catTodas
      }

      this.toSearch()
    },
    setBtnSearch () {
      // console.log(`setBtnSearch: q: ${this.q}, cat: ${this.catCurrrent.id}, type: ${this.type}`)
      // if (this.q !== null && this.q !== '') {
      // this.toSearch()
      let go = ''
      switch (this.type) {
        case 'lead':
          go = 'leadSearch'
          break
        case 'catalogo':
          go = 'catalogoSearch'
          break
        case 'catalogoPublic':
          go = 'catalogoPublicSearch'
          break
        case 'orderBuy':
          go = 'pedidosComprarSearch'
          break
        default:
      }

      const idCat = (this.catCurrrent) ? this.catCurrrent.id : ''
      // console.log(`setBtnSearch-> idCat: ${idCat}, go: ${go}`)
      this.$router.push({'name': go, 'query': { 'q': this.q, 'cat': idCat }})
      // }
    },
    removeSearch () {
      // console.log(`remove: ${this.q}`)
      if (!this.q) {
        let go = ''
        switch (this.type) {
          case 'lead':
            go = 'leadOferta'
            break
          case 'catalogo':
            go = 'catalogoList'
            break
          case 'catalogoPublic':
            go = 'catalogoListPublic'
            break
          case 'orderBuy':
            go = 'pedidosComprar'
            break
          default:
        }
        this.$router.push({'name': go})
      }
      console.log(go, this.q)
    }
  },
  watch: {
    '$route' (to, from) {
      // console.log('to: ', to)
      // console.log('from: ', from)
      // react to route changes...
      if (to.name === 'catalogoSearch' || to.name === 'leadSearch' || to.name === 'catalogoPublicSearch' || to.name === 'pedidosComprarSearch') {
        // Si volvemos a la pag de búsqueda.
        this.setUrlSearch()
      } else if (
        (to.name === 'catalogoList' && from.name === 'catalogoSearch') ||
        (to.name === 'leadOferta' && from.name === 'leadSearch') ||
        (to.name === 'catalogoListPublic' && from.name === 'catalogoPublicSearch') ||
        (to.name === 'pedidosComprar' && from.name === 'pedidosComprarSearch')
      ) {
        // console.log(`watch: ${to.name}, ${from.name}`)
        // si vamos al catálogo y venimos de la página de búsqueda. Quitamos el texto de búsqueda y realizamos una nueva búsuqeda.
        this.q = ''
        this.toSearch()
      }
    },
    catSelect () {
      if (this.catSelect.length && this.catRouter) {
        const idCat = this.catRouter
        const cat = this.catSelect.filter(item => item.id === parseInt(idCat))
        this.catCurrrent = cat[0]
        this.toSearch()
      }
    },
    category (newValue) {
      // console.log(`watch category -> idCat: ${this.catCurrrent.id} newValue: `, newValue)
      // Si nos viene una categoría diferente a la que tenemos en el buscador, le reseteamos.
      if (!newValue || (this.catCurrrent.id !== newValue.id)) {
        this.catCurrrent = this.catTodas
        /* if (this.$route.name === 'leadSearch') {
          this.$router.push({'name': 'leadOferta'})
        } else {
          this.$router.push({'name': 'catalogoList'})
        } */
      }
    }
  }
}
</script>
<style lang="scss" scoped>

  input[type="search"]{
    height:40px;
    line-height: 40px;
  }

  .dropdown{
    display:table-cell;
    vertical-align: middle;
    width: 200px;
    border-right: 1px solid #717171;

    &.open{
      //.dropdown-toggle{
        background-color: #fefefe;
      //}
      .dropdown-menu li{
        span{
          padding: 5px 12px;
        }
      }
    }

    .dropdown-toggle{
      width:100%;
      height:40px;
      line-height: 40px;
      padding: 0 12px;
      @include roundAll(0);
      border:none;
      font-size: 14px;
      color: #646464;

      &:hover, &:focus{
        background-color: #fefefe;
      }

      i{
        background-color: transparent;
        color: $blue-light;
        line-height: 40px;
        font-size: 18px;
        width: 24px;
      }
    }
  }

  .btn{
    padding-top:0;
    padding-bottom:0;
    height:40px;
    line-height: 40px;
  }
</style>
