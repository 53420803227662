import Vue from 'vue'
// Config
import appConfig from '@/config-app.js'

const formatMoney = {}

formatMoney.install = function (vue) {
  Vue.filter('formatMoney', (val) => {
    const decimalsNumMax = appConfig.configApp.decimals_num_max
    return Number(val).toLocaleString('de-DE', { minimumFractionDigits: decimalsNumMax, maximumFractionDigits: decimalsNumMax })
  })
}

export default formatMoney
