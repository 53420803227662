<template lang="pug">
  .Migas-estadisticas
    p(v-for="(item, index) in migas")
      span(v-if="index") >
      strong {{item}}
</template>

<script>
// import * as types from '@/store/mutation-types'
export default {
  name: 'EstadisticasMigas',
  data () {
    return {
    }
  },
  computed: {
    migas () {
      if (this.$store.getters.dashboard && this.$store.getters.dashboard.node_name) {
        return ['Todas', this.$store.getters.dashboard.node_name]
      }
      return ['Todas']
    }
  },
  methods: {
  },
  watch: {
  }

}
</script>

<style lang="scss" scoped>
  .Migas-estadisticas{
  }

  p{
    display: inline-block;
    font-size: 14px;
    margin: 0;

    &:first-child{
      span{
        margin-left: 0;
      }
    }

    &:not(:last-child){
      span, strong{
        color: $blue-light;
      }
    }

    button{
      background-color: transparent;
      border: none;
      padding: 0;
    }
    span{
      display: inline-block;
      margin: 0 4px;
    }

    strong{
      font-weight: normal;
    }
  }
</style>
