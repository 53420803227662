<template lang="pug">
  .listadoDesplegableGrid
    .container
      .row.grid-head
        .col-sm-5
          p #[translate Descripción]
        .col-sm-2(v-if="isTypeApp === 'pedrosa'")
          p #[translate Ref.]
        .col-sm-2(v-else)
          p #[translate Código]
        .col-sm-1
          p.text-right #[translate Precio]
        .col-sm-1
          p.text-right #[translate Cantidad]
        //.col-sm-2
          p.text-center #[translate Fecha entrega/prevista]
        .col-sm-2.total-cn
          p.text-right #[translate Total artículo]
        .col-sm-1.status-cn
          p &nbsp;
          //#[translate Estado]

    .grid-cn
      bk-item(v-for="item in aProduct" :key="item.id" :entries="item" :currency_symbol="currency_symbol")

    .container.box-3
      .row
        .col-sm-8.text-right.pull-right
            button.btn.btn-white(@click="setOrder()") #[translate Ver pedido]
            button.btn.btn-action(@click="replicar()" v-if="client") #[translate Añadir a la cesta]
</template>
<script>

import BkItem from '@/components/order/mis-pedidos/listado-desplegable-item.vue'
import SrvOrder from '@/services/orders'

export default {
  name: 'ListadoDesplegableGrid',
  props: ['id', 'showDetalle'],
  components: { BkItem },
  data () {
    return {
      'aProduct': [],
      'numItems': null,
      'currency_symbol': null
    }
  },
  created () {
    if (this.showDetalle) {
      this.getOrder()
    }
  },
  computed: {
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    client () {
      return this.$store.getters.client
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    getOrder () {
      // console.log('id: ' + this.id)
      SrvOrder.getOrder(this.id)
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            // console.log(`Pedido single: `, data)

            this.aProduct = data.entries
            this.numItems = this.aProduct.length
            this.currency_symbol = data.currency_symbol
            // console.log(`number `, res)
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    replicar () {
      // console.log('Replicar productos:', this.aProduct)

      // const numProduct = this.aProduct.length

      this.aProduct.map((item, index) => {
        // console.log(`${item.quantity}, ${item.product.id}`)

        let obj = {
          'client': this.client.id,
          'product': item.product.id,
          'quantity': item.quantity,
          'description': '',
          'billable': false
        }
        if (this.config_app.id_code_0 !== item.product.id) {
          let IdAditionalMeasurementUnit = item.aditional_measurement_unit ? item.aditional_measurement_unit.id : null
          this.$store.dispatch('addCart', {idProduct: item.product.id, quantity: item.quantity, product: obj, aditional_measurement_unit: IdAditionalMeasurementUnit, 'hide': true})
        }
        return this.$router.push({name: 'cartList'})
      })
    },
    setOrder () {
      // Para ir al detalle de la orden
      // console.log('Order seleccionada: ', this.order.id)
      this.$router.push({name: 'pedidoSingle', params: {id: this.id}})
    }
  },
  watch: {
    showDetalle () {
      if (this.showDetalle) {
        this.getOrder()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.listadoDesplegableGrid{
  max-width: 1100px;
  margin: 20px auto 0;
}

.listadoDesplegableItem{

  &:nth-child(odd){
    background-color: #F5F7F9;
  }

  &:last-child{

  }
}

.box-3{
  padding-top: 20px;
  border-top: 1px solid rgba(#545454, .46);
  .btn{
    transform: translateX(15px);
    margin-left: 20px;
  }
}

.total-cn{
  padding-right:30px;
}

.status-cn{
  padding-left: 0;
}

</style>
