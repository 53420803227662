<template lang="pug">
  #product-Single
      .container
        .row
          .col-sm-12
            bk-migas(:migas="migasSend" @toFilterCategory="toFilterCategory")
      bk-product(v-if="id" :id="id" @toGoProduct="toGoProduct" @toAddCart="toAddCart" @toSendMigas="toSendMigas")
      bk-modal-stock-0(ref="modalStock0" id="modal-stock-0" :product="productAdd")
      p.hidden
        button(data-toggle="modal" data-target="#modal-stock-0" class="btn-modal" ref="showModalStock0")
</template>
<script>
import {mapGetters} from 'vuex'

import BkMigas from '@/components/catalog/Migas-list.vue'
import BkProduct from '@/components/catalog/Product.vue'
import BkModalStock0 from '@/components/common/Modal-stock-0.vue'
export default {
  name: 'ProductSingle',
  components: { BkModalStock0, BkMigas, BkProduct },
  props: ['id', 'migas'],
  data () {
    return {
      migasSend: [],
      productAdd: null
    }
  },
  computed: {
    ...mapGetters([
      'role'
    ]),
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  created () {
    // console.log('----------+', this.migas)

  },
  methods: {
    showModalStock0 () {
      // En el HTML hay un botón con el atributo ref="showModal" y de esta forma le hacemos click para mostrar la modal.
      this.$refs.showModalStock0.click()
    },
    toFilterCategory (v) {
      // console.log(`--- toFilterCategory: ${v}`)
      this.$router.push({'name': 'catalogoList', 'params': {'catProductSingle': {id: v}}})
    },
    toAddCart (item) {
      // console.log(`Component page/CatalogoList -> id product: ${item.idProduct}, quantity: ${item.quantity} , item: `, item.product)
      // Envia al carrito
      // alert('Product single: Enviamos a la Store del carrito.')
      if (!item.product.stock) {
        this.productAdd = item
        if (!item.product.is_ondemand){
          this.showModalStock0()
        }
      }
      this.$store.dispatch('addCart', item)

      // Y redirijimos al catálogo
      // this.$router.go(-1)
    },
    toGoProduct (id) {
      // console.log(`-----> toGoProduct: ${id}`)
      this.idProduct = id
      this.$router.push({'name': 'productSingle', 'params': {'id': id.toString(), 'migas': this.migas}})
    },
    toSendMigas (migas) {
      this.migasSend = migas
    }
  },
  watch: {
    migas () {
      // console.log('--------->migas:', this.migas)
    }
  }
}
</script>
<style lang="scss" scoped>

#catalog-migas{
  padding-left: 0;
}

</style>
