
import { HorizontalBar } from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  props: ['valueBar'],
  data () {
    return {
      'options': {
        'responsive': true,
        'maintainAspectRatio': false,
        'legend': {
          'display': false // Oculta el Label del Dataset
        },
        'tooltips': {
          'enabled': false
        },
        'hover': {
          'animationDuration': 1
        },
        'animation': {
          'duration': 1,
          'onComplete': function () {
            var chartInstance = this.chart
            var ctx = chartInstance.ctx
            ctx.textAlign = 'right'
            ctx.fillStyle = '#ffffff'
            ctx.textBaseline = 'bottom'
            ctx.font = '16px Arial'

            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i)
              meta.data.forEach(function (bar, index) {
                var data = dataset.valueReal // dataset.data[index]
                var typeBar = dataset.typeBar
                if (typeBar === 'dato') {
                  let posX = (data < 30) ? -30 : 10
                  ctx.fillText(`${data}%`, bar._model.x - posX, bar._model.y + 10)
                }
              })
            })
          }
        },
        'scales': {
          'yAxes': [{
            'stacked': true,
            'display': true,
            'barThickness': 40, // altura barra,
            'ticks': {
              'display': false // Para que no se vea la leyenda de la izquierda.
            },
            'gridLines': {
              'color': 'transparent'
              // display: false,
              // drawBorder: false, //hide the chart edge line
              // 'tickMarkLength': 200 // Padding entre lineas y ticks
            }
          }],
          'xAxes': [{
            'display': true,
            'position': 'bottom',
            'ticks': {
              'display': true,
              'stepSize': 100,
              'min': 0,
              'max': 100,
              'fontColor': '#c4c5c7',
              'fontSize': 14,
              'padding': 10, // Espacio entre la línea y el texto de su label
              'callback': function (value, index, values) {
                // console.log(`index: ${index}, value: ${value}`)
                if (value === 100) {
                  return `Objetivo ${value}%`
                }
                return `${value}%`
              }
            },
            'gridLines': {
              'display': true, // lineas
              'drawBorder': false, // línea inicial
              // tickMarkLength: 100
              'zeroLineWidth': 2, // Grosor línea inicial
              'zeroLineColor': '#e6eaee', // Color línea inical
              'lineWidth': 2, // Grosor líneas
              'color': '#e6eaee', // Color Líneas
              // 'tickMarkLength': 25
              'drawTicks': false // centra la barra
            }
          }]
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.datasets, this.options)
  },
  computed: {
    datasets () {
      return {
        'labels': [],
        'datasets': [
          {
            'typeBar': 'dato', // Parámetro propio para detectar cual es la barra con el dato real.
            'valueReal': this.valueBar,
            'fill': true,
            'backgroundColor': '#005f90',
            'data': [this.maxValueTop]
          },
          {
            'typeBar': 'background', // Parámetro propio para detectar cual es la barra de fondo.
            'valueReal': 100,
            'fill': true,
            'backgroundColor': '#f95d5e',
            'data': [100]
          }
        ]
      }
    },
    maxValueTop () {
      let value = (this.valueBar > 100) ? 100 : this.valueBar
      return value
    }
  },
  watch: {
    datasets () {
      // console.log('++++', this.datasets)
      this.renderChart(this.data, this.options)
    },
    valueBar () {
      // console.log(`change---> ${this.valuesData.dataOne[0]} , ${this.datasets.datasets[0].data[0]}`)
      this.renderChart(this.datasets, this.options)
    }
  }
}
