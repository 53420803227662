// Importamos mutaciones
import * as types from '@/store/mutation-types'

const lead = {
  state: {
    isLead: !!window.localStorage.getItem('_lead'),
    lead: null
  },
  actions: {
    setLead ({commit}, data) {
      window.localStorage.setItem('_lead', JSON.stringify(data))
      commit(types.SET_LEAD)
      commit(types.LEAD_SUCCESS)
    },
    removeLead ({commit}) {
      window.localStorage.removeItem('_lead')
      commit(types.REMOVE_LEAD)
    }
  },
  mutations: {
    [types.LEAD_SUCCESS] (state) {
      state.isLead = true
    },
    [types.REMOVE_LEAD] (state) {
      state.lead = null
      state.isLead = false
    },
    [types.SET_LEAD] (state) {
      state.lead = JSON.parse(window.localStorage.getItem('_lead'))
      // console.log('-->state.lead: ' + state.lead)
    }
  },
  getters: {
    isLead: state => {
      return state.isLead
    },
    lead: state => {
      return state.lead
    }
  }
}

export default lead
