<template lang="pug">
  #dashboardAlbaranesRankingCenter
    .container.box-1
      .row
        .col-sm-2.text-center
          a(href="#" @click="goBack()")
            i(class="fa fa-angle-left" aria-hidden="true")
            span #[translate Volver]
        .col-sm-6
          p.title-cn(v-if="client") #[translate Cliente]:
            strong  {{client.name}} ({{client.center_count}} #[translate locations])
        .col-sm-4
          bk-search(@toSearch="toSearch")
    .container.box-2
      .row
        .col-sm-12
          bk-grid(
            :clients="aCenters"
            :message="message"
            :pages="totalPages"
            :count="count"
            :currentPage="currentPage"
            @toPaginate="toPagChange"
            @toOrdering="toOrdering")
</template>

<script>
import BkSearch from '@/components/dashboard/Search.vue'
import BkGrid from '@/components/dashboard/Grid-delivery-ranking-center.vue'

// Srv
import SrvDashboard from '@/services/albaranes'
import SrvClients from '@/services/clients'

export default {
  name: 'DashboardVentasRankingCenter',
  components: {BkSearch, BkGrid},
  props: ['id', 'year'],
  data () {
    return {
      'idUserDashboard': null, // Id del usuario de que se mostraran los datos.
      // buscador
      'query': '', // Donde se guarla la palabra a buscar.
      // Ordenar
      'ordering': '', // Ordenar por nombre ascendente, los valores de ordering son: 'name', 'period_sales', 'period_sales_target', 'year_sales', 'year_sales_target'
      'setTimeoutToSearch': null,
      // paginación
      'message': null,
      'aCenters': [],
      'count': null,
      'totalPages': 0,
      'currentPage': 1 // Para saber porque página vamos en la paginación
    }
  },
  computed: {
    dashboardStore () {
      let dashboard = this.$store.getters.dashboard
      if (dashboard) {
        this.idUserDashboard = dashboard.id
      }
      return dashboard
    },
    client () {
      return this.$store.getters.client
    }
  },
  created () {
    // console.log(`id Cliente: ${this.id}`)
    this.getClient()
    if (this.idUserDashboard) {
      this.getDeliveryRankingCenter()
    }
  },
  methods: {
    getDeliveryRankingCenter () {
      if (!this.year) {
        // this.goBack()
        return false
      }

      SrvDashboard.getDeliveryRankingCenter(this.idUserDashboard, this.id, this.query, this.currentPage, this.ordering, '', this.year)
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard VentasRankingCenter -> getDeliveryRankingCenter: ', res.data)
            this.aCenters = res.data.results
            this.count = res.data.count
            this.totalPages = res.data.total_pages
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    getClient () {
      SrvClients.getClientById(this.id)
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard VentasRankingCenter -> Client: ', res.data)
            let client = res.data
            // Enviamos a Store Client los datos del cliente seleccionado
            this.$store.dispatch('setClient', client)
            // Lo pasamos a su State
            this.$store.commit('SET_CLIENT')
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    goBack () {
      this.$router.go(-1)
    },
    toSearch (query) {
      // console.log('query:' + query)
      window.clearTimeout(this.setTimeoutToSearch)
      var self = this
      this.setTimeoutToSearch = window.setTimeout(function () {
        self.resetGrid()
        self.query = query
        self.getDeliveryRankingCenter()
      }, 500)
    },
    toPagChange (pageNum) {
      // console.log(pageNum)
      this.currentPage = pageNum
      this.getDeliveryRankingCenter()
    },
    toOrdering (value) {
      // console.log('---' + value)
      this.currentPage = 1
      this.ordering = value
      if (this.idUserDashboard) {
        this.getDeliveryRankingCenter()
      }
    },
    resetGrid () {
      // reseteamos los parámetros del grid, paginación, orden.
      this.currentPage = 1
      this.ordering = ''
      this.query = ''
    }
  },
  watch: {
    dashboardStore () {
      // console.log(`han cambiado de cliente, reenviamos al usuario a Ranking principal, ay que no tienen los mismos centros, etc...`)
      // let dashboard = this.$store.getters.dashboard
      // this.idUserDashboard = dashboard.id
      this.$router.push({name: 'dashboardAlbaranesRanking'})
    }
  }
}
</script>

<style lang="scss" scoped>
  .box-1{
    padding-top: 30px;
    padding-bottom: 16px;

    a{
      color: $blue-light;
      padding: 12px;
      display: inline-block;
      background-color: #f7f9fb;
      font-size: 16px;

      i{
        margin-right: 6px;
      }
    }

    .title-cn{
      font-size: 16px;
      //padding-top: 12px;

      strong{
        font-weight: $regular;
        font-size: 18px;
      }
    }
  }

  .box-2{
    padding: 0;
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
    border-top: 1px solid $grey-border;
  }
</style>
