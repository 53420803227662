<template lang="pug">
  div
    // Mostramos Zona listado.
    .lead-list(v-if="$route.name === 'leadsList'")
      .box-2
        .container
          .row
            .col-sm-9
              bk-search(@toSearch="toSearch")
            .col-sm-3.text-right
              button(class="btn btn-action" @click="createLead()") #[translate Crear nuevo Lead]

      .box-3
        .container
          .row
            .col-sm-8
              p.info-cn #[translate Leads]
                small(:class="{'fade-in': itemsNum}")  ({{itemsNum}} #[translate leads])

      bk-grid(
        :items = "aItems"
        :message = "message"
        :pages = "totalPages"
        :count = "itemsNum"
        :currentPage = "currentPage"
        @toPaginate = "pagChange"
        @toChangeComercial = "changeComercial"
        @toChangeOrderingCode = "changeOrderingCode")

    // Mostramos el detalle de la oferta.
    div(v-if="$route.name !== 'leadsList'")
      transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
        router-view
</template>
<script>
import BkSearch from '@/components/lead/Search.vue'
import BkGrid from '@/components/lead/Grid.vue'

// importamos servicios Leads
import SrvLeads from '@/services/lead'

export default {
  name: 'LeadsList',
  components: { BkSearch, BkGrid },
  data () {
    return {
      'message': null,
      'aItems': [],
      'itemsNum': null,
      'totalPages': 0,
      'currentPage': 1, // Para saber porque página vamos en la paginación
      'query': '', // Donde se guarla la palabra a buscar.
      'filterComercial': '', // Para filtrar por comercial
      'orderingCode': '-external_id_int' // Para ordenar po código.
    }
  },
  created () {
    // llamamos a la API una vez se crea la instancia.
    this.search('', 1)
  },
  methods: {
    pagChange (pageNum) {
      // console.log(`pagChange -> Query: ${this.query}, pageNum: ${pageNum}`)
      this.currentPage = pageNum
      this.search()
    },
    changeComercial (item) {
      // console.log(`change comercial: ${item.id} --->`, item)
      this.filterComercial = item.id
      this.search()
    },
    changeOrderingCode (ordering) {
      // console.log(`changeOrderingCode: ${ordering}`)
      this.orderingCode = ordering
      this.currentPage = 1
      this.search()
    },
    toSearch (query) {
      this.query = query
      this.currentPage = 1
      this.search()
    },
    search () {
      // console.log(`---> Search: ${query}`)
      window.scrollTo(0, 0) // movemos el scroll arriba
      SrvLeads.getLeads(this.query, this.currentPage, this.filterComercial, this.orderingCode)
        .then(res => {
          if (res.status === 200) {
            // console.log('---> Leads: ', res.data.results)
            // console.log('---> Leads: ', res.data.results[0])
            this.aItems = res.data.results
            this.itemsNum = res.data.count
            this.totalPages = res.data.total_pages

            // console.log(`currenPage: ${this.currentPage}, page: ${page}`)
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, error => {
          // console.log('----->' + error)
          let code = error.toString()
          // console.log(code + '-------------' + code.indexOf('403'))
          if (code.indexOf('403')) {
            // console.log('redirect')
            // this.$router.push('/')
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        })
    },
    createLead () {
      this.$router.push({'name': 'leadCreate'})
    }
  }
}
</script>
<style lang="scss" scoped>
  .box-2{
    background-color: #EFEFEF;
    padding: 14px 0;

    .text-right{
      button{
        font-size: 14px;
      }
    }
  }

  .box-3{
    padding: 26px 0 20px;

    .row{
      display: flex;
      align-items: center;
    }

    .info-cn{
      font-size: 16px;
      margin: 0;

      small{
        font-size: 16px;
      }
    }
  }
</style>
