// Stores
import Stores from '@/store/index'

// Importamos mutaciones
import * as types from '@/store/mutation-types'

// importamos servicios
import SrvFavorites from '@/services/favorites'

const favorite = {
  state: {
    'favorites': {
      'items': []
    },
    addItem: {} // item añadido más reciente.
  },
  actions: {
    getAllFavorite ({commit}) {
      SrvFavorites.getAll()
        .then(res => {
          // console.log('res: ', res.data)
          // Actualizamos los favoritos.
          commit(types.FAVORITE_UPDATE, res.data.results)
        }, () => {
          // console.log('----->' + error)
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
      return null
    },
    addItemFavorite ({commit}, id) {
      SrvFavorites.add(id)
        .then(res => {
          // console.log('res: ', res.data)
          // Actualizamos los favoritos.
          commit(types.FAVORITE_GET_ITEMS)
        }, () => {
          // console.log('----->' + error)
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
      return null
    },
    removeAllFavorites ({commit}) {
      SrvFavorites.removeAll()
        .then(res => {
          // console.log('res: ', res)
          // Actualizamos el carrito.
          commit(types.FAVORITE_GET_ITEMS)
        }, () => {
          // console.log('----->' + error)
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
      return null
    },
    removeItemFavorite ({commit}, id) {
      SrvFavorites.remove(id)
        .then(res => {
          // console.log('res: ', res)
          // Actualizamos el carrito.
          commit(types.FAVORITE_GET_ITEMS)
        }, () => {
          // console.log('----->' + error)
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
      return null
    }
  },
  mutations: {
    [types.FAVORITE_UPDATE] (state, data) {
      state.favorites.items = data
    },
    [types.FAVORITE_GET_ITEMS] (state) {
      Stores.dispatch('getAllFavorite')
    }
  },
  getters: {
    'favoritesCount': state => {
      return state.favorites.items.length
    },
    'favoritesItems': state => {
      return state.favorites.items
    }
  }
}
export default favorite
