<template lang="pug">
  .Migas-estadisticas
    p(v-for="(item, index) in migas")
      span(v-if="index") >
      button(v-if="index < migas.length - 1" @click="changeMigas(index)")
        span {{item}}
      strong(v-else) {{item}}
</template>

<script>
// import * as types from '@/store/mutation-types'
export default {
  name: 'EstadisticasMigas',
  data () {
    return {}
  },
  computed: {
    migas () {
      // return  ['Total', 'Barcelona', 'ARGENTONA', '40638']
      return this.$store.state.client.clientDashboardOptions.migasName
    },
    migasId () {
      // return  ['Total', 'Barcelona', 'ARGENTONA', '40638']
      return this.$store.state.client.clientDashboardOptions.migasId
    }
  },
  methods: {
    changeMigas (index) {
      // console.log(`change migas: ${index}`)
      // let aMigasName = this.migas.splice(index + 1, this.migas.length)
      // let aMigasId = this.migasId.splice(index + 1, this.migasId.length)
      // this.$store.commit(types.UPDATE_CLIENT_DASHBOARD_OPTIONS, {'migasId': aMigasId, 'migasName': aMigasName})
    }
  },
  watch: {
    migas () {
      // console.log(11)
    }
  }

}
</script>

<style lang="scss" scoped>
  .Migas-estadisticas{
  }

  p{
    display: inline-block;
    color: $blue-light;
    font-size: 14px;
    margin: 0;

    &:first-child{
      span{
        margin-left: 0;
      }
    }

    button{
      background-color: transparent;
      border: none;
      padding: 0;
    }
    span{
      display: inline-block;
      margin: 0 3px;
    }

    strong{
      font-weight: normal;
      color: #1C2C40;
    }
  }
</style>
