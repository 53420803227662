
// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

// console.log('token service Client: ' + window.localStorage.getItem('_token'))

export default {
  // Árbol sidebar
  getNodeTree () {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let oClient = Stores.state.client.client
      if (oClient) {
        let client = oClient.id
        // llamada a la API de clientas
        axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
        axios.get(`/c_dashboard/stat_node_tree/?client=${client}`)
          .then(res => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)

            // console.log('Dashboard SERV getNodeTree: ', res)
            resolve(res)
          })
          .catch(error => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)
            const status = error.response.status
            if (status === 401) {
              SrvCommon.logout()
            }
            reject(error)
          })
      } else {
        // Si no hay cliente la respuesta es 0
        resolve(0)
      }
    })
  },
  getClientPurchases (migas, before, after, filterCat) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let param = ''
      if (migas.length) {
        let migasTmp = migas.slice(1) // Eliminamos el primer item del array "Total"
        param = migasTmp.map((value, key) => `level${key}=${value}`).join('&') // creamos los parámetros del árbol (level0=Barcelona&level1=ARGENTONA&level2=40638).
      }

      let oClient = Stores.state.client.client
      if (oClient) {
        let client = oClient.id

        let url = `/c_dashboard/client_purchases/?client=${client}&before=${before}&after=${after}&category=${filterCat}&${param}`
        // console.log('---' + url)
        // llamada a la API de clientas
        axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
        axios.get(url)
          .then(res => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)

            // console.log('Dashboard SERV getNodeTree: ', res)
            resolve(res)
          })
          .catch(error => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)
            const status = error.response.status
            if (status === 401) {
              SrvCommon.logout()
            }
            reject(error)
          })
      } else {
        // Si no hay cliente la respuesta es 0
        resolve(0)
      }
    })
  },
  getClientPurchasesCategories (migas, before, after) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let param = ''
      if (migas.length) {
        let migasTmp = migas.slice(1) // Eliminamos el primer item del array "Total"
        param = migasTmp.map((value, key) => `level${key}=${value}`).join('&') // creamos los parámetros del árbol (level0=Barcelona&level1=ARGENTONA&level2=40638).
      }

      let oClient = Stores.state.client.client
      if (oClient) {
        let client = oClient.id

        let url = `/c_dashboard/client_categories_purchases/?client=${client}&before=${before}&after=${after}&${param}`
        // console.log('---' + url)
        // llamada a la API de clientas
        axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
        axios.get(url)
          .then(res => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)

            // console.log('Dashboard SERV getNodeTree: ', res)
            resolve(res)
          })
          .catch(error => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)
            const status = error.response.status
            if (status === 401) {
              SrvCommon.logout()
            }
            reject(error)
          })
      } else {
        // Si no hay cliente la respuesta es 0
        resolve(0)
      }
    })
  },
  /*
    RANKING de centros.
  */
  getClientPurchasesCenter (migas, page, before, after, filterCat) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let param = ''
      if (migas.length) {
        let migasTmp = migas.slice(1) // Eliminamos el primer item del array "Total"
        param = migasTmp.map((value, key) => `level${key}=${value}`).join('&') // creamos los parámetros del árbol (level0=Barcelona&level1=ARGENTONA&level2=40638).
      }

      let oClient = Stores.state.client.client
      if (oClient) {
        let client = oClient.id

        let url = `/c_dashboard/client_center_purchases/?client=${client}&page=${page}&before=${before}&after=${after}&category=${filterCat}&${param}`
        // console.log('---' + url)
        // llamada a la API de clientas
        axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
        axios.get(url)
          .then(res => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)

            // console.log('Dashboard SERV getNodeTree: ', res)
            resolve(res)
          })
          .catch(error => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)
            const status = error.response.status
            if (status === 401) {
              SrvCommon.logout()
            }
            reject(error)
          })
      } else {
        // Si no hay cliente la respuesta es 0
        resolve(0)
      }
    })
  },
  /*
    RANKING de artículos.
  */
  getClientPurchasesProduct (migas, page, before, after, filterCat) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let param = ''
      if (migas.length) {
        let migasTmp = migas.slice(1) // Eliminamos el primer item del array "Total"
        param = migasTmp.map((value, key) => `level${key}=${value}`).join('&') // creamos los parámetros del árbol (level0=Barcelona&level1=ARGENTONA&level2=40638).
      }

      let oClient = Stores.state.client.client
      if (oClient) {
        let client = oClient.id

        let url = `/c_dashboard/client_product_purchases/?client=${client}&page=${page}&before=${before}&after=${after}&category=${filterCat}&${param}`
        // console.log('---' + url)
        // llamada a la API de clientas
        axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
        axios.get(url)
          .then(res => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)

            // console.log('Dashboard SERV getNodeTree: ', res)
            resolve(res)
          })
          .catch(error => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)
            const status = error.response.status
            if (status === 401) {
              SrvCommon.logout()
            }
            reject(error)
          })
      } else {
        // Si no hay cliente la respuesta es 0
        resolve(0)
      }
    })
  },
  // download xlsx
  getXlsx (url) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      delete axios.defaults.headers.common['Accept']
      axios.get(url, {
        responseType: 'blob'
      })
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Dashboard SERV getNodeTree: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  /*
    Racionalización total.
  */
  getRationalizationProductTotal (migas, before, after, filterCat) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let param = ''
      if (migas.length) {
        let migasTmp = migas.slice(1) // Eliminamos el primer item del array "Total"
        param = migasTmp.map((value, key) => `level${key}=${value}`).join('&') // creamos los parámetros del árbol (level0=Barcelona&level1=ARGENTONA&level2=40638).
      }

      let oClient = Stores.state.client.client
      if (oClient) {
        let client = oClient.id

        let url = `/c_dashboard/product_rationalization_total/?client=${client}&before=${before}&after=${after}&category=${filterCat}&${param}`
        // console.log('---' + url)
        // llamada a la API de clientas
        axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
        axios.get(url)
          .then(res => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)

            // console.log('Dashboard SERV getNodeTree: ', res)
            resolve(res)
          })
          .catch(error => {
            // Ocultamos Loading
            Stores.commit(types.NOT_PENDING)
            const status = error.response.status
            if (status === 401) {
              SrvCommon.logout()
            }
            reject(error)
          })
      } else {
        // Si no hay cliente la respuesta es 0
        resolve(0)
      }
    })
  },
  /*
    Listado de categorías principales para filtro.
  */
  getfilterCatList () {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      const url = `/categories/`
      // console.log('---' + url)
      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(url)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('Dashboard SERV getNodeTree: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }
}
