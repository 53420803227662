<template lang="pug">
  .catalog-GridItem(v-if="product" :class="{'type-grid': view=='grid', 'type-outlet': product.is_outlet, 'type-eco': product.is_eco}" )
    small(v-if="product.is_eco" :class="{'!tw-bg-[#9CE16D]': product.is_outlet}") #[translate Eco]
    small(v-if="product.is_outlet" :class="{'!tw-top-[25px]': product.is_eco}") #[translate Outlet]
    .category
      span {{ categoryFirst }}
    span.img-cn
      img(:src="checkEmptyImg(product)" @click="goProduct()")
      bk-favorite(class="favorite-img" :data-id="product.id")
    .details
      h3(@click="goProduct()")
        span {{checkEmpty(product.name)}}
      p.code-cn #[translate Ref.] {{product.external_id}}
      .action(v-if="isLoggedIn")
        .Precio-box
            p
              span.und(v-if="config_app.stock_availability" class="normalStock") to be delisted XXXX
              span.und(v-else-if="!config_app.stock_availability && config_app.stock && !product.is_network" :class="{'noStock': !product.stock}") #[translate Stock] {{product.stock}} #[translate Und].
              span.und(else) &nbsp;
        .box-2(v-if="product.price === null")
          bk-cotiza.text-right( :product="product" type="list")
    .details(style="100%;clear:both;height:40px;overflow: inherit;")
      .action

        .box-2(style="justify-content: right;" v-if="!hiddenAddCart && (isClient || isLead)")

          .formato(v-if="config_app.formato && aFormato && aFormato.length > 0 && !product.only_boxes")
            .dropdown.Select-bk
              button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddFormato" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{formatoItem.name}} ({{formatoItem.quantity}})
                i(class="fa fa-angle-down" aria-hidden="true")
              ul(class="dropdown-menu" aria-labelledby="ddFormato")
                li(v-for="item in aFormato" :class="{'disabled': formatoItem.id == item.id}")
                  span(:value="item.id" @click="changeFormato(item)") {{item.name}}
                    span(v-if="item.id") &nbsp;#[translate de] {{item.quantity}} #[translate uds].
          .formato(v-else-if="product.only_boxes")
            .dropdown.Select-bk {{ aFormato[0].name }}
          p(v-else) &nbsp;
          span.pvp(v-if="product.price !== null" :class="{'special':config_app.showSpecialPrice && specialPriceType, 'no-stock': !product.stock, 'obsolete': product.obsolete}" style="text-align:right" ) {{ price | format-money}} {{product.currency_symbol}}
          form.cant
            input(type="number" placeholder="0" min="0" :max="limitQuantity" v-model="quantity" @change="checkQuanty")
            i(class="fa fa-shopping-cart" aria-hidden="true" @click="addCart()")
</template>
<script>
import {mapGetters} from 'vuex'

import BkFavorite from '@/components/common/favorite.vue'
import BkCotiza from '@/components/common/Cotiza.vue'

export default {
  name: 'CatalogGridItem',
  props: ['product', 'view'],
  components: { BkFavorite, BkCotiza },
  data () {
    return {
      'formatoItem': {'name': '', 'quantity': 1},
      'quantity': 1,
      'meses': [this.$gettext('Ene'), this.$gettext('Feb'), this.$gettext('Mar'), this.$gettext('Abr'), this.$gettext('May'), this.$gettext('Jun'), this.$gettext('Jul'), this.$gettext('Ago'), this.$gettext('Sep'), this.$gettext('Oct'), this.$gettext('Nov'), this.$gettext('Dic')],
      'specialPriceType': false // Si tiene precio especial
    }
  },
  computed: {
    ...mapGetters([
      'role', 'isLoggedIn'
    ]),
    price () {
      let precio = this.product.price
      if (this.product.special_price !== null) {
        precio = this.product.special_price
        this.specialPriceType = true
        if (this.type_app === 'pedrosa' && this.role === 'client') {
          this.specialPriceType = false
        }
      }
      if (this.formatoItem) {
        precio = precio * this.formatoItem.quantity
      }
      return precio
    },
    center () {
      return this.$store.state.center.center
    },
    isClient () {
      return this.$store.getters.isClient
    },
    isLead () {
      return this.$store.getters.isLead
    },
    hiddenAddCart () {
      // si  no tiene precio lo ocultamos
      if (this.product.price === null) {
        return true
      }
      // Si no hay stock y tiene activado el limite, ocultamos el botón de comprar.
      if (!this.product.stock && this.config_app.stock_limit) {
        return true
      }
      // Ocultamos el botón de añadir el carrito cuando no tengamos stock, obsolete_limit: true y obsolete: true
      if (!this.product.stock && this.product.obsolete && this.config_app.obsolete_limit) {
        return true
      }
      return false
    },
    limitQuantity () {
      // Si tenemos activo el limite lo ponemos en cada caja de cantidad.
      if (this.config_app.stock_limit) {
        return this.product.stock
      }

      // Si obsolete: true ponemos el liminte del stock a quantity
      return this.product.obsolete ? this.product.stock : ''
    },
    aFormato () {
      // formato
      let aFormatos = this.product.aditional_measurement_units
      if (aFormatos && aFormatos.length > 0) {
        const unit = this.product.measurement_unit
        let formato = {'id': '', 'name': this.$gettext('Unidad'), 'quantity': 1}
        if (unit) {
          formato = {'id': '', 'name': unit.name, 'quantity': unit.quantity}
        }
        if (aFormatos && aFormatos.length > 0) {
          aFormatos = [formato, ...aFormatos]
          if (this.product.only_boxes) {
            let boxFormat = aFormatos.filter(f => f.external_code === 'CAJ' || f.external_id === 'CAJ')
            this.changeFormato(boxFormat[0])
            return boxFormat
          } else {
            this.changeFormato(aFormatos[0])
          }
        }
        return aFormatos
      }
      return []
    },
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    categoryFirst () {
      if (this.product.categories && this.product.categories.length > 1) {
        return this.product.categories[1].name
      }
      return '-'
    }
  },
  methods: {
    addCart () {
      //  console.log(`---id product: ${this.product.id}, quantity: ${this.quantity}`)
      if (this.quantity > 0) {
        let item = {idProduct: this.product.id, quantity: this.quantity, product: this.product}
        if (this.config_app.formato && this.aFormato.length > 0) {
          item.aditional_measurement_unit = this.formatoItem.id
        }
        if (this.product.is_ondemand) {
          this.$store.commit('MODAL_ON_DEMAND', true)
        }
        this.$emit('toAddCart', item)
      }
    },
    goProduct () {
      // this.$router.push({name: 'catalogProduct', params: {id: this.product.id}})
      const slug = this.product.id + '-' + this.$options.filters.slugable(this.product.name)
      this.$emit('toGoProduct', [slug])
    },
    checkEmpty (v) {
      return (!v) ? '-' : v
    },
    checkEmptyImg (product) {
      if (product.image_url !== null) {
        return product.image_url
      } else if (this.config_app.extra_images && this.product.extra_images && this.product.extra_images.length > 0) {
        // Mostramos la imagen del cliente (ej. primsa) en lugar de la de Orbita.
        return product.extra_images[0]
      } else {
        return require('@/assets/img/no_disponible.png')
      }
      // return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    },
    checkQuanty () {
      // console.log(`obsolete: ${this.product.obsolete}, obsolete_limit: ${this.config_app.obsolete_limit}, quantity: ${this.quantity}`)
      // obsolete_limit: true y product obsolete: true no dejamos que pase el limite del stock.
      if (this.product.obsolete && this.config_app.obsolete_limit) {
        this.quantity = Math.max(Math.min(Math.round(this.quantity), this.limitQuantity), 0)
      }
      this.$emit('toChangeQuantity', {'quantity': this.quantity, 'idProduct': this.product.id})
    },
    changeFormato (item) {
      // console.log(`id: ${item.id}, name: ${item.name}, quantity: ${item.quantity}`)
      this.formatoItem = item
    }
  }
}
</script>
<style lang="scss" scoped>
.category{
  background-color: #E8F4FC;
  padding: 3px;
  font-size: 12px;
}
  .catalog-GridItem{
    position: relative;
    margin-bottom: 12px;
    border:1px solid #ffffff;
    background-color: #ffffff;
    text-align: left;
    width:218px;
    height: 200px;
    @include transition();

    &.type-new{
      border-color: rgba(#8D22A7, .77);

      & > small{
        background-color: #4467C8;
      }
    }

    &.type-eco{
      border-color: #9CE16D;

      & > small{
        background-color: #9CE16D;
      }
    }

    &.type-outlet{
      border-color: #f0bd4e;

      & > small{
        background-color: #f0bd4e;
      }
    }

    &:hover, &:focus{
      border-color: #1592E6;
    }

    & > small{
      position: absolute;
      z-index: 9;
      top:23px;
      left:0;
      display: block;
      padding: 4px;
      color: $white;
      text-transform: uppercase;
      font-size:10px;
    }
  }
  .img-cn{
    width: 72px;
    height:90px;
    text-align: center;
    display: block;
    float: left;
    justify-content: center;
    align-items: center;
    margin:10px 0 14px;
    overflow: hidden;
    padding:9px;
    img{
      max-width: 100%;
      max-height: 80px;
      height:auto;
      cursor: pointer;
    }
  }
  .details{
    height: 120px;
    overflow: hidden;
    h3{
      color:#303030;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: $regular;
      margin:0;
      cursor:pointer;
      @include transition();
      min-height: 48px;
      @include multiEllipsis (48px, 3);
      -webkit-box-orient: vertical;
      margin-top:15px;
    }

    .code-cn{
      font-size: 10px;
      color: #ADADAD;
      margin: 0 0 4px;
    }

    .action{
      width:100%;
      margin-top: 0;
      
      padding:9px;
      padding-top: 0;

      .Precio-box{
        p{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .und{
          font-size: 10px;
          span:first-child{
            color:#303030;
          }
        }
      }

      .box-2{
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 40px;
        strong{
          float:left;
          margin-top:4px;
        }
        .pvp{
          font-size: 14px;
          font-weight: 500;
          display: inline-block;
          color: #43425D;
          border-radius: 3px;
          -ms-border-radius: 3px;
          -moz-border-radius: 3px;
          -webkit-border-radius: 3px;
          -khtml-border-radius: 3px;
          padding: 2px 8px;
          &.special{
            color: #fff!important;
            background-color: $color-precio-special;
          }
        }
      }

      .cant{
        display:flex;
        width:90px;
        border: 1px solid #E6E8E9;
        @include roundAll(4);
        overflow: hidden;
      }

      input{
        width:50px;
        border:none;
        height: 27px;
        line-height: 27px;
        text-align: center;
      }

      i{
        width:40px;
        background-color:#eff3f6;
        color: $orange;
        text-align: center;
        height: 27px;
        line-height: 27px;
        cursor:pointer;
        @include transition();

        &:hover, &:focus{
          background-color:$orange;
          color:$white;
        }
      }

      .formato {
        width: 100px;
          .Select-bk.dropdown{
            margin: 0;
            width: 100%;
            .dropdown-menu{
              width: 160px;
              left: inherit;
              right: auto;
            }
          }

          .dropdown-toggle{
            @include roundAll(4);
            line-height: 34px;
            height: 34px;
            border: 1px solid #E6E8E9;

            i{
              line-height: 34px;
            }

            &:hover, &:focus{
              background-color: #fff;
            }
          }
      }
    }
  }

  .cotiza-common.text-right{
    width: 100%;
  }

</style>
