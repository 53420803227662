<template lang="pug">
  .ofertas-grid
    .Loader-cn(:class="{'show': isPdfLoading}")
      span.Loader-spinner
    .container(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}

    .container(v-if="count == 0")
      .row
        .col-sm-12
          div(class="alert alert-info" role="alert") #[translate No se han encontrado presupuestos].

    .holder-cn(v-if="count")
      .container
        .row.grid-head
          .col-sm-4
            p.name #[translate Nombre del cliente]
          .col-sm-2
            p.text-right 
              span #[translate Nº de Presupuesto]
              <Sort orderBy="number,offer_datetime" :orderByActual="ordering" @sort="$emit('setOrdering', $event)" class="tw-ml-2"></Sort>

          .col-sm-2
            p.text-center 
              span #[translate Fecha]
              <Sort orderBy="offer_datetime,number" :orderByActual="ordering" @sort="$emit('setOrdering', $event)" class="tw-ml-2"></Sort>

          .col-sm-2
            p.text-right #[translate Total Pedido]
          .col-sm-2
            .row
              .col-sm-8
                p.text-right #[translate Ver presupuesto]
              .col-sm-4
                p.text-center

      .grid-cn(v-if="count")
        .container
          bk-item(v-for="(item, index) in items" :key="item.id" :item="item" :index="index" @setOnPdfLoading="setOnPdfLoading" @setOffPdfLoading="setOffPdfLoading")

      .container.text-center(v-if="pages > 1")
        ek-paginate(:page-count="pages"
          :page-range="4"
          :margin-pages="2"
          :click-handler="pagChange"
          :prev-text="paginationTxt.prev"
          :next-text="paginationTxt.next"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :initial-page="currentPage-1"
          v-show="pages")
</template>
<script>
import EkPaginate from 'vuejs-paginate'
import BkItem from '@/components/ofertas/Item.vue'
import Sort from '@/components/common/Sort.vue'

export default {
  name: 'OfertasGrid',
  props: ['items', 'message', 'pages', 'count', 'currentPage', 'ordering'],
  data () {
    return {
      paginationTxt: {
        'prev': this.$gettext('Anterior'),
        'next': this.$gettext('Siguiente')
      },
      isPdfLoading: false
    }
  },
  components: { BkItem, EkPaginate, Sort },
  computed: {
  },
  methods: {
    setOnPdfLoading () {
      this.isPdfLoading = true
    },
    setOffPdfLoading () {
      this.isPdfLoading = false
    },
    pagChange (pageNum) {
      // console.log(pageNum)
      this.$emit('toPaginate', [pageNum])
    }
  }
}
</script>
<style lang="scss" scoped>

  .grid-head{
    margin-top: 6px;
    margin-bottom: 6px;
    & > *{
      padding-bottom: 6px;
      border-bottom: 1px solid #1C2C40;
      font-size: 14px;

      &:first-child{
        font-weight: $medium;
      }
    }

    p{
      margin: 0;
    }
  }

  .grid-cn{
    margin-bottom: 24px;

    & > *{
      padding:0;
    }

    .ofertas-list-item{
      &:nth-child(even){
        background-color: rgba(#F1F4F7, .38);
      }

      &:last-child{
          border-bottom: 1px solid #1C2C40;
      }
    }
  }

  .alert{
    margin-top:40px;
  }

</style>
