
// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

// console.log('token service Client: ' + window.localStorage.getItem('_token'))

export default {
  // Árbol sidebar
  getNodeTree () {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/sp_dashboard/stat_node_tree/`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Dashboard SERV getNodeTree: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  // Dashboard - resumen
  getSummary (id, year) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/sp_dashboard/summary/?node=${id}&year=${year}`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Dashboard SERV getSummary: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  // Dashboard - Pedidos
  getOrders (id, year) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/sp_dashboard/orders/?node=${id}&year=${year}`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Dashboard SERV getSummary: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  // Dashboard - Ventas resumen
  getSalesSummary (id, clientStatus, before, after) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      const url = `/sp_dashboard/sales/?node=${id}&client_status=${clientStatus}&before=${before}&after=${after}`

      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(url)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Dashboard SERV getSummary: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  // Dashboard - Estado de los usuarios
  getClientStatus () {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/sp_dashboard/client_status/`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('Dashboard SERV getClientStatus: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  // Dashboard - Ventas ranking Client
  getSalesRanking (id, querySearch, page, clientStatus, ordering, before, after) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/sp_dashboard/clients/?node=${id}&page=${page}&search=${querySearch}&client_status=${clientStatus}&ordering=${ordering}&before=${before}&after=${after}`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Dashboard SERV getSummary: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  // Dashboard - Ventas ranking Center
  getSalesRankingCenter (id, idClient, querySearch, page, ordering, showAll) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API de clientas
      /**
        * show_all = Si es True, hace que salgan los centros sin ventas
        */
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/sp_dashboard/centers/?node=${id}&client=${idClient}&page=${page}&search=${querySearch}&ordering=${ordering}&show_all=${showAll}`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Dashboard SERV getSummary: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  // Dashboard - Ventas Perdidos
  getSalesLosts (id, querySearch, page, ordering) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/sp_dashboard/lost_clients/?node=${id}&page=${page}&search=${querySearch}&ordering=${ordering}`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Dashboard SERV getSalesLosts: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  // Dashboard - Ventas Proveedores - listado.
  getSuppliers () {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/suppliers/?prefered=True`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('Dashboard SERV getSalesLosts: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('+++' + error)
          reject(error)
        })
    })
  },
  // Dashboard - Ventas Proveedores - datos graficos.
  getPreferedSuppliers (id, idSuppliers, before, after) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let url = `/sp_dashboard/prefered_suppliers/?node=${id}&before=${before}&after=${after}`
      url += (idSuppliers > 0) ? `&supplier=${idSuppliers}` : ''

      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(url)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('Dashboard SERV getPreferedSuppliers: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('+++' + error)
          reject(error)
        })
    })
  },
  // Dashboard - Ventas Ofertas.
  getOffers (id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let url = `/sp_dashboard/offers/?node=${id}`

      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(url)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('Dashboard SERV getPreferedSuppliers: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('+++' + error)
          reject(error)
        })
    })
  },
  // Dashboard - Ventas Eficacia - datos graficos.
  geteEfficiency (id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let url = `/sp_dashboard/efficiency/?node=${id}`

      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(url)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('Dashboard SERV getPreferedSuppliers: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('+++' + error)
          reject(error)
        })
    })
  }
}
