import SrvOfertas from '@/services/ofertas.js'
import SrvCatalogos from '@/services/cataloggenerator.js'
export default {
    methods: {
        async downloadOfferPdf(offer, isViewDetail = false) {
            !isViewDetail ? this.$emit('setOnPdfLoading') : this.isPdfLoading = true
            const res = await SrvOfertas.getOfertaPDF(offer.id)
            if (res.status === 200) {
                const blob = new Blob([res.data], {type: 'application/pdf'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `Presupuesto-${offer.number}.pdf`
                link.click()
            }
            !isViewDetail ? this.$emit('setOffPdfLoading') : this.isPdfLoading = false
        },
        async downloadCatalogPdf(catalog, isViewDetail = false) {
            !isViewDetail ? this.$emit('setOnPdfLoading') : this.isPdfLoading = true
            const res = await SrvCatalogos.getCatalogGeneratorPDF(catalog.id)
            if (res.status === 200) {
                const blob = new Blob([res.data], {type: 'application/pdf'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `Catalogo-${catalog.number}.pdf`
                link.click()
            }
            !isViewDetail ? this.$emit('setOffPdfLoading') : this.isPdfLoading = false
        }
    },
}