/*

  ref:
  https://codepen.io/Karl53/pen/MbYrPN

*/
import { Line } from 'vue-chartjs'
import appConfig from '@/config-app.js'

export default {
  extends: Line,
  props: ['valuesData'],
  data () {
    return {
      'options': {
        'responsive': true,
        'maintainAspectRatio': false,
        'legend': {
          'display': false // Oculta el Label del Dataset
        },
        'tooltips': appConfig.dashboardConfig.tooltips,
        'animation': {
          // 'animateScale': true,
          'animateRotate': true,
          'onComplete': function () {
          }
        },
        'scales': {
          'yAxes': [
            {
              'type': 'linear',
              // 'display': true,
              'stacked': false,
              'position': 'left',
              'ticks': {
                'display': true,
                'min': 0,
                // 'max': 800,
                'fontColor': '#45A5F5',
                'fontSize': 14
              },
              'gridLines': {
                'tickMarkLength': 40 // Padding entre lineas y ticks
              }
            }
          ],
          'xAxes': [{
            // 'display': true,
            'stacked': true,
            'barThickness': 40, // Grosor barra
            'ticks': {
              'display': true,
              'fontColor': '#232323',
              'fontSize': 12,
              'padding': 20
            }
          }]
        }
      }
    }
  },
  mounted () {
    // console.log('--', this.datasets)
    this.renderChart(this.datasets, this.options)
  },
  computed: {
    datasets () {
      return {
        'labels': this.valuesData.labels,
        'datasets': [
          {
            'type': 'line',
            'lineTension': 0,
            'pointStyle': 'rect', // tipo rectangulo de punto
            'pointRadius': 5,
            'borderColor': this.valuesData.dataColor.one.borderColor,
            'pointBackgroundColor': this.valuesData.dataColor.one.pointBackgroundColor,
            'borderWidth': 2,
            'fill': false,
            'data': this.valuesData.dataOne
          },
          {
            'type': 'line',
            'lineTension': 0,
            'pointStyle': 'rect', // tipo rectangulo de punto
            'pointRadius': 5,
            'borderColor': this.valuesData.dataColor.two.borderColor,
            'pointBackgroundColor': this.valuesData.dataColor.two.pointBackgroundColor,
            'borderWidth': 2,
            'fill': false,
            'data': this.valuesData.dataTwo
          },
          {
            'type': 'line',
            'lineTension': 0,
            'pointStyle': 'rect', // tipo rectangulo de punto
            'pointRadius': 5,
            'borderColor': this.valuesData.dataColor.three.borderColor,
            'pointBackgroundColor': this.valuesData.dataColor.three.pointBackgroundColor,
            'borderWidth': 2,
            'fill': false,
            'data': this.valuesData.dataThree
          }
        ]
      }
    }
  },
  methods: {
    getDiffPorcent () {
      return 100 - this.valuesData.value
    }
  },
  watch: {
    valuesData () {
      // console.log(`change---> ${this.valuesData.dataOne[0]} , ${this.datasets.datasets[0].data[0]}`)
      this._data._chart.destroy()
      this.renderChart(this.datasets, this.options)
    }
  }
}
