<template lang="pug">
  div( :id="idModal" class="modal fade" role="dialog")
    .modal-dialog.modal-lg
      .modal-content
        .modal-header
          button(v-if="this.$config.type !== 'prisma'" type="button" class="close" data-dismiss="modal")
            img(:src="img.close" width="12")
          h2(v-if="title") {{title}}
        .modal-body
          div(v-html="content")
        .model-footer(v-if="footer")
          p {{footer}}
    span(class="hidden") #[translate xxx#/]
</template>
<script>
export default {
  name: 'ModalCommon',
  props: ['id', 'title', 'content', 'footer'],
  data () {
    return {
      img: {
        close: require('@/assets/img/icos/cerrar-01.svg')
      }
    }
  },
  computed: {
    idModal () {
      if (this.id) {
        return this.id
      }
      return 'modal-common'
    }
  },
  created () {
  }
}
</script>
<style lang="scss" scoped>
  /*.modal-header{
    padding-top:30px;
    padding-bottom: 24px;
    background-color: $blue-bg;
    color: $white;

    .close{
      margin-top: -20px;
      font-size: 30px;
      color: $white;
      @include opacity(0.7);
    }
  }

  .modal-body{
    // padding-left:0;
    // padding-right: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 16px;
  }*/

  .modal-content{
    border: 1px solid #17296E;
    @include roundAll(5);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    box-shadow: 0 3px 6px rgba(0,0,0,.25);
    overflow: hidden;
  }
  .modal-header{
    background-color: #EBF3FE;
    padding-top:22px;
    padding-bottom: 22px;

    .close{
      margin-top: -5px;
      font-size: 40px;
      opacity: 1;
    }
  }

  .modal-body{
    // padding-left:0;
    // padding-right: 0;
  }

  h2{
    font-size: 18px;
    margin: 0;
  }
</style>
