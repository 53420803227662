// Stores
import Stores from '@/store/index'
// Importamos mutaciones
import * as types from '@/store/mutation-types'

const client = {
  state: {
    isClient: !!window.localStorage.getItem('_client'),
    client: null,
    clientCatalog: null,
    catalogueOptions: {'ordering': '', 'filterViewProducts': '', 'filterByType': []},
    clientDashboardOptions: {'migasId': [], 'migasName': []}, // dónde guardaremos las migas de estadísticas.
    clientDashboardDate: {'after': '', 'before': ''} // Dónde guardamos las fechas de estadísticas
  },
  actions: {
    setClient ({commit}, data) {
      window.localStorage.setItem('_client', JSON.stringify(data))

      // reseteamos las opciones al cambiar de cliente.
      commit(types.UPDATE_CATALOGUE_OPTIONS, {'ordering': '', 'filterViewProducts': '', 'filterByType': []})

      commit(types.SET_CLIENT)
      commit(types.CLIENT_SUCCESS)
    },
    updateClient ({commit}, data) {
      window.localStorage.setItem('_client', JSON.stringify(data))
      commit(types.UPDATE_CLIENT)
    },
    removeClient ({commit}) {
      // eliminamos centro.
      Stores.dispatch('removeCenter')
      // eliminamos el carro.
      Stores.dispatch('deleteCart')
      // Eliminamos el cliente
      window.localStorage.removeItem('_client')
      commit(types.REMOVE_CLIENT)
    },
    setClientCatalog ({commit}, data) {
      commit(types.SET_CLIENT_CATALOG, data)
    },
    setCatalogueOptions ({commit}, data) {
      commit(types.UPDATE_CATALOGUE_OPTIONS, {})
      commit(types.UPDATE_CATALOGUE_OPTIONS, data)
    }
  },
  mutations: {
    [types.CLIENT_SUCCESS] (state) {
      state.isClient = true
    },
    [types.REMOVE_CLIENT] (state) {
      state.client = null
      state.isClient = false
    },
    [types.SET_CLIENT] (state) {
      state.client = JSON.parse(window.localStorage.getItem('_client'))
      // console.log('-->state.client: ' + state.client)
    },
    [types.UPDATE_CLIENT] (state) {
      state.client = JSON.parse(window.localStorage.getItem('_client'))
      // console.log('-->state.client: ' + state.client)
    },
    [types.SET_CLIENT_CATALOG] (state, data) {
      state.clientCatalog = data
    },
    [types.UPDATE_CATALOGUE_OPTIONS] (state, data) {
      // console.log('>>>>state: ', state + ', data: ', data)
      state.catalogueOptions = data
      // console.log('-->state.client: ' + state.client)
    },
    [types.UPDATE_CLIENT_DASHBOARD_OPTIONS] (state, data) {
      state.clientDashboardOptions = data
      // console.log('-->state.client: ' + state.client)
    },
    [types.UPDATE_CLIENT_DASHBOARD_DATE] (state, data) {
      state.clientDashboardDate = data
      // console.log('-->state.client: ' + state.client)
    }
  },
  getters: {
    isClient: state => {
      return state.isClient
    },
    client: state => {
      return state.client
    },
    clientCatalog: state => {
      return state.clientCatalog
    },
    catalogueOptions: state => {
      return state.catalogueOptions // {'ordering': 'price_desc', 'filterViewProducts': 'all'}
    },
    clientDashboardOptions: state => {
      return state.clientDashboardOptions
    },
    clientDashboardDate: state => {
      return state.clientDashboardDate
    }
  }
}

export default client
