<template lang="pug">
  #dashboardProveedores(v-if="oPreferedSuppliers")
    .head-box
      .container
        .row
          .col-sm-12
            h2 #[translate Mis Pedidos]
        .row
          .col-sm-6
            bk-migas
    .container.box-1
      .row
        .col-sm-3.chart-cn.type-num
          p.title-cn #[translate Ventas Período:]
          p.value-cn {{oPreferedSuppliers.period_sales | format-money}}€
          p.leyenda-cn #[span(v-if="oPreferedSuppliers.period_sales_diff > 0") +]{{oPreferedSuppliers.period_sales_diff_percentage}}% #[translate vs Año anterior]
        .col-sm-3.chart-cn.type-euro
          p.title-cn #[translate Ventas Año anterior:]
          p.value-cn {{oPreferedSuppliers.year_sales | format-money}}€
          // p.leyenda-cn  #[strong(:class="{'red': oPreferedSuppliers.year_sales_diff < 0}") {{oPreferedSuppliers.year_sales_diff | format-money}}] #[span(v-if="oPreferedSuppliers.year_sales_diff > 0") +]{{oPreferedSuppliers.year_sales_diff_percentage}}% #[translate vs Año acumulado anterior]
        .col-sm-3.text-right
          .dropdown
            button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") #[translate Proveedores:] #[em {{proveedor}}]
              i(class="fa fa-angle-down" aria-hidden="true")
            ul(class="dropdown-menu" aria-labelledby="ddOrder")
              li(v-for="(item, key) in aProveedores" :class="{'disabled': proveedor == item.name}")
                span( @click="changeProveedor(item)") {{item.name}}
        .col-sm-3.text-right
          date-picker(class="calendar-cn" v-model="calendar.date" :lang="calendar.lang" :format="calendar.format" type="month" range :clearable="calendar.clearable" :shortcuts="calendar.shortcuts" not-before="2016-01-01" @input="changeCalendar" confirm)
    .container.box-chartLine
      .row
        .col-sm-12.text-right.chart-legend
          p #[translate Ventas Período] #[translate Objetivo Período]
      .row
        .col-sm-12
          bar-line(:valuesData="chartLineValues")
    .container.box-chartDonut
      .col-sm-6.chart-cn.type-donut(v-if="!idSuppliers")
        p.title-cn #[translate Ventas Proovedores preferentes] vs #[translate Ventas total]
        .chart-data
          donut-general(:valuesData="totalData")
          small {{totalData.value}}%
        p.leyenda-cn.type-menos #[em {{oPreferedSuppliers.period_sales | format-money}}€] vs #[em {{oPreferedSuppliers.period_sales_global | format-money}}€]
      .chart-cn.type-donut(:class="{'col-sm-6': !idSuppliers, 'col-sm-12': idSuppliers}")
        p.title-cn #[translate Ventas Período] vs #[translate Objetivo]
        .chart-data
          donut-general(:valuesData="ventasData")
          small {{ventasData.value}}%
        p.leyenda-cn.type-menos #[em {{oPreferedSuppliers.period_sales | format-money}}€] vs #[em {{oPreferedSuppliers.period_sales_target | format-money}}€]
      // .chart-cn.type-donut(:class="{'col-sm-4': !idSuppliers, 'col-sm-6': idSuppliers}")
          p.title-cn #[translate Ventas Acumulado Año] vs #[translate Objetivo]
          .chart-data
            donut-general(:valuesData="proyeccionData")
            small {{proyeccionData.value}}%
          p.leyenda-cn #[em {{oPreferedSuppliers.year_sales | format-money}}€] vs #[em {{oPreferedSuppliers.year_sales_target | format-money}}€]
</template>

<script>
import appConfig from '@/config-app.js'
import BarLine from '@/components/dashboard/chart/BarLineTwo'
import DonutGeneral from '@/components/dashboard/chart/DonutGeneral'
import DatePicker from 'vue2-datepicker'
import BkMigas from '@/components/dashboard/migas'

// Srv
import SrvDashboard from '@/services/dashboard'

export default {
  name: 'DashboardProveedores',
  components: { DonutGeneral, BarLine, DatePicker, BkMigas },
  data () {
    return {
      'idUserDashboard': null,
      'oPreferedSuppliers': null,
      'idSuppliers': 0,
      'proveedor': this.$gettext('Todos'),
      'aProveedores': [],
      'chartLineValues': {
        'labels': [],
        'lineOne': {
          'color': '#45A5F5',
          'data': []
        },
        'lineTwo': {
          'color': '#71c061',
          'data': []
        }
      },
      'totalData': {},
      'ventasData': {},
      'proyeccionData': {},
      'calendar': {
        'before': this.$moment().add(-1, 'months').format('YYYY-MM-DD[T]HH:mm:ss'),
        'after': this.$moment().startOf('year').format('YYYY-MM-DD[T]HH:mm:ss'),
        'date': [this.$moment().startOf('year').format('YYYY-MM'), this.$moment().add(-1, 'months').format('YYYY-MM')],
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'MM/YYYY' // 'MMM YYYY'
      }
    }
  },
  created () {
    // console.log('-------', this.dashboardStore.node_name)
    this.getSuppliers()
    if (this.idUserDashboard) {
      this.getPreferedSuppliers()
    }
  },
  computed: {
    dashboardStore () {
      let dashboard = this.$store.getters.dashboard
      if (dashboard) {
        this.idUserDashboard = dashboard.id
      }
      return dashboard
    }
  },
  methods: {
    getPreferedSuppliers () {
      SrvDashboard.getPreferedSuppliers(this.idUserDashboard, this.idSuppliers, this.calendar.before, this.calendar.after)
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard getPreferedSuppliers: ', res.data.results)
            this.oPreferedSuppliers = res.data

            // chartLine
            this.chartLineValues = {'labels': [], 'lineOne': {'color': '#45A5F5', 'data': []}, 'lineTwo': {'color': '#71c061', 'data': []}}
            let chart = this.oPreferedSuppliers.chart
            chart.map(item => {
              // console.log('---', item) // date, num_orders, value_orders
              this.chartLineValues.labels.push(item.date)
              this.chartLineValues.lineOne.data.push(item.sales_value)
              this.chartLineValues.lineTwo.data.push(item.sales_target_value)
            })

            // Donut
            this.totalData = {}
            this.totalData.value = this.oPreferedSuppliers.period_sales_global_percentage
            this.totalData.color = (this.totalData.value < 0) ? '#f95d5d' : '#005ec8'

            this.ventasData = {}
            this.ventasData.value = this.oPreferedSuppliers.period_sales_target_precentage
            this.ventasData.color = (this.ventasData.value < 100) ? '#f95d5d' : '#7fd04e'

            this.proyeccionData = {}
            this.proyeccionData.value = this.oPreferedSuppliers.year_sales_target_precentage
            this.proyeccionData.color = (this.proyeccionData.value < 100) ? '#f95d5d' : '#7fd04e'
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    getSuppliers () {
      SrvDashboard.getSuppliers()
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard getSuppliers: ', res.data.results)
            this.aProveedores = [{'id': 0, 'name': this.$gettext('Todos')}, ...res.data.results]
            // console.log('---', this.aProveedores)
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeProveedor (item) {
      // console.log(`changeProveedor: ${item.id} - ${item.name}`)
      this.proveedor = item.name
      this.idSuppliers = item.id
      this.getPreferedSuppliers()
    },
    changeCalendar (value) {
      let aDate = value.toString().split(',')
      if (aDate.length === 2) {
        this.calendar.after = this.$moment(aDate[0]).format('YYYY-MM-DD[T]HH:mm:ss')
        this.calendar.before = this.$moment(aDate[1]).endOf('month').format('YYYY-MM-DD[T]HH:mm:ss')
        // console.log(`${this.calendar.after}, ${this.calendar.before}`)
        this.getPreferedSuppliers()
      }
    }
  },
  watch: {
    dashboardStore () {
      // console.log(`dashboard --> name: ${this.dashboardStore.node_name} , id: ${this.dashboardStore.id}`)
      const dashboard = this.$store.getters.dashboard
      this.idUserDashboard = dashboard.id
      this.getPreferedSuppliers()
    }
  }
}
</script>

<style lang="scss" scoped>
  #dashboardProveedores{
      background-color: $white;
      padding: 24px 28px;
      overflow: hidden;
      margin-bottom: 40px;
  }
  .head-box{
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -30px;
      right: -30px;
      border-bottom: 1px solid #E8E8E8;
    }
    .container{
      padding:0;
    }
    h2{
      margin: 0 0 8px;
      color: #1C2C40;
      font-size: 15px;
      font-weight: $medium;
    }
  }
  .box-1{
    padding: 0px 0 20px;
  }

  .chart-data{
    position: relative;
    width: 146px;
    height: 146px;
    margin: 15px auto 24px;

    & > div{
      position: relative;
      height: 100%;
    }

    small{
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      width: 100%;
      height: 22px;
      line-height: 22px;
      margin: auto;
      color: #008bb5;
      font-size: 20px;
      font-weight: $medium;
      text-align:center;
    }
  }

  .chart-cn{
    .title-cn{
      color:#2c373f;
      font-size: 16px;
      margin-bottom: 2px;
      span{
        display:block;
      }
    }

    .value-cn{
      font-size: 24px;
      font-weight: $bold;
      margin-bottom: 8px;
    }

    .leyenda-cn{
      font-size: 13px;
      color: #6a889e;

      em{
        font-size: 16px;
        color: #5d6368;
        font-style: normal;
      }

      strong{
        position: relative;
        display: inline-block;
        color: #26d119;
        font-weight: $regular;
        &:before{
          content:'▴';
          display: inline-block;
          margin-right: 4px;
        }

        &.red{
          color: #f80000;
          &:before{
            content:'▾';
          }
        }
      }

    }

    &.type-num{
      border-right: 2px solid #e6eaee;
      .value-cn{
        color: #45A5F5;
      }
    }

    &.type-euro{
      .value-cn{
        color: #2F5EC4;
      }
    }

    .type-donut{
      text-align: center;
      border-left: 2px solid #e6eaee;
      &:first-child{
        border: none;
      }

      .title-cn{
        color: #7e7f80;
        font-size: 18px;

        span{
          color:#2c373f;
        }
      }

      .leyenda-cn{
        strong{
          display: block;
          font-size: 24px;
          font-weight: $medium;
          color: #7fd04e;
        }

        &.type-menos{
          strong{
            color: #f95d5d;
          }
        }
      }
    }

  }

  .dropdown{
    display:inline-block;
    width: 100%;
    max-width: 390px;
    vertical-align: top;
    //min-width: 200px;
    margin-left: 20px;

    li, button{
      color: #494f58;
      font-size: 14px;
    }

    .dropdown-toggle{
      width: 100%;
      height: 34px;
      line-height: 34px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 50px;
      color: $blue-light;

      span{
        color: #494949;
      }

      strong{
        color: #68889e;
        font-weight: $regular;
      }

      em{
        font-style: normal;
        max-width: 250px;
        display: inline-block;
        overflow: hidden;
        vertical-align: middle;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      i.fa-calendar{
        position: relative;
        color: #494949;
        background-color: transparent;
        font-size: 14px;
        width: 16px;
        margin-right: 6px;
      }

      i.fa-angle-down{
        width:40px;
        line-height: 36px;
        background-color: transparent;
        color: $blue-light;
      }
    }
  }

  .box-chartLine{
    margin-bottom:80px;

    .row + .row{
      margin-top: 20px;
    }
    .chart-legend{
      text-align: right;
      font-size: 12px;
      color: #232323;

      span{
        position: relative;
        display: inline-block;
        margin-right:16px;

        &:before{
          content:'';
          width: 10px;
          height: 10px;
          display: inline-block;
          margin-right: 6px;
          vertical-align: middle;
        }

        &:nth-child(1){
          &:before{
            background-color: #45A5F5;
          }
        }
        &:nth-child(2){
          &:before{
            background-color: #71c061; // #f95d5d;
          }
        }
      }
    }
  }

  .calendar-cn{
    width: 210px;
    margin-left: 18px;
  }
</style>
