<template>
  <div class="tsm-modal">
    <Transition name="modal-wrapper">
      <div v-if="show" :class="[justifyContent, alignItems]" class="tw-overflow-y-auto tw-bg-[#00000080] tw-overflow-x-hidden tw-fixed tw-w-screen tw-h-screen tw-top-0 tw-left-0 tw-py-4 tw-z-[100] tw-h-modal md:tw-inset-0 tw-flex">
        <Transition name="modal">
          <div v-if="internalShow" class="tw-relative tw-px-4 tw-w-full tw-h-full md:tw-h-auto" :class="[maxWidthClass]">
            <div class="tw-relative tw-bg-white tw-rounded-2xl tw-shadow">
              <slot name="header" :close="close">
                <div class="tw-flex tw-justify-between tw-items-start tw-p-5 tw-rounded-t tw-border-b">
                  <h3 class="tw-text-xl tw-font-semibold tw-text-gray-900 lg:tw-text-2xl">Modal Title</h3>
                  <button @mousedown="close" type="button" class="tw-text-gray-400 tw-bg-transparent hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-rounded-lg tw-text-sm tw-p-1.5 tw-ml-auto tw-inline-flex tw-items-center" data-modal-toggle="defaultModal">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </button>
                </div>
              </slot>

              <slot name="body">
                <div class="tw-p-6 tw-space-y-6">
                  <p class="tw-text-base tw-leading-relaxed tw-text-gray-500">Modal Body</p>
                </div>
              </slot>

              <slot name="footer" :close="close">
                <div class="tw-flex tw-items-center tw-p-6 tw-gap-4 tw-rounded-b tw-border-t tw-border-gray-200">
                  <button data-modal-toggle="defaultModal" type="button" class="tw-text-white tw-bg-blue-700 hover:tw-bg-blue-800 focus:tw-ring-4 focus:tw-ring-blue-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 py-2.5 tw-text-center">Button 1</button>
                  <button data-modal-toggle="defaultModal" type="button" class="tw-text-gray-500 tw-bg-white hover:tw-bg-gray-100 focus:tw-ring-4 focus:tw-ring-gray-300 tw-rounded-lg tw-border tw-border-gray-200 tw-text-sm tw-font-medium tw-px-5 tw-py-2.5 hover:tw-text-gray-900 focus:tw-z-10">Button 2</button>
                </div>
              </slot>
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "Modal_v2",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    maxWidthClass: {
      type: String,
      default: 'tw-max-w-4xl'
    },
    justifyContent: {
      type: String,
      default: 'tw-justify-center'
    },
    alignItems: {
      type: String,
      default: 'tw-items-center'
    },
  },
  data() {
    return {
      internalShow: false
    }
  },
  methods: {
    close() {
      this.internalShow = false
      setTimeout(() => {
        this.$emit('update:show', false)
      }, 250)
    }
  },
  watch: {
    show(state) {
      this.$nextTick(() => {
        this.internalShow = state
      })
    }
  }
}
</script>

<style>
.modal-wrapper-enter-active {
  animation: modal-wrapper-in 0.5s;
}

.modal-wrapper-leave-active {
  animation: modal-wrapper-out 0.5s;
}

@keyframes modal-wrapper-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modal-wrapper-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.modal-enter-active {
  animation: modal-in 0.5s;
}

.modal-leave-active {
  animation: modal-out 0.5s;
}

@keyframes modal-in {
  0% {
    opacity: 0;
    transform: translateY(-10vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modal-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10vh);
  }
}

html {
  font-size: 16px !important;
}
</style>
