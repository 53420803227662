<template lang="pug">
  .albaranDesplegableItem
     .container
      .row(@click="setOrder")
        .c-l #[translate Albarán] #[strong {{entries.delivery_note_number}}]
        .c-c #[translate Fecha entrega] {{entries.delivery_note_datetime | moment("DD-MM-YYYY")}}
        .c-r #[translate Cantidad servida]: {{entries.quantity}}

</template>
<script>
export default {
  name: 'AlbaranDesplegableItem',
  props: ['entries'],
  data () {
    return {}
  },
  created () {
    // console.log(`---> ${this.entries.delivery_note_number}`)
  },
  methods: {
    setOrder () {
      // console.log('Order seleccionada: ', this.order.id)
      this.$router.push({name: 'albaranSingle', params: {id: this.entries.delivery_note_id}})
    }
  }
}
</script>
<style lang="scss" scoped>

  .row{
    display: flex;
    font-size: 13px;
    cursor: pointer;
  }

  strong{
    color: $blue-light;
    font-weight: $regular;
  }

  .c-l{
      margin-left: 100px;
      margin-right: 64px;
  }

  .c-c{
    margin-right: 64px;
  }
</style>
