import Stores from "@/store/index";
import * as types from "@/store/mutation-types";
import appConfig from "@/config-app.js";

import axios from "axios";
axios.defaults.baseURL = appConfig.api;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {
  getSideBoxInfo() {
    const oCenter = Stores.getters.center;
    const idCenter = oCenter ? oCenter.id : "";

    const query = `/center_co2e/${idCenter}`;

    axios.defaults.headers.common["Authorization"] =
      "JWT " + window.localStorage.getItem("_token");

    if (Stores.getters.getCancelAxios) {
      Stores.getters.getCancelAxios.cancel(
        "Start new search, stop active search"
      );
    }

    Stores.commit(types.CANCEL_AXIOS, axios.CancelToken.source());

    return axios.get(query, {
      cancelToken: Stores.getters.getCancelAxios.token,
    });
  },
  getSimulatorInfo() {
    const oClient = Stores.getters.client;
    const idClient = oClient ? oClient.id : "";

    const oCenter = Stores.getters.center;
    const idCenter = oCenter ? `&center=${oCenter.id}` : "";

    const query = `/simulator_co2e/?client=${idClient}${idCenter}`;

    axios.defaults.headers.common["Authorization"] =
      "JWT " + window.localStorage.getItem("_token");

    return axios.get(query, {
      cancelToken: Stores.getters.getCancelAxios.token,
    });
  }
};
