<template lang="pug">
  #Lead-product-Single
      .container
        .row
          .col-sm-12
            bk-migas(:migas="migasSend" @toFilterCategory="toFilterCategory")
      bk-product(v-if="id" :id="id" @toGoProduct="toGoProduct" @toAddCart="toAddCart" @toSendMigas="toSendMigas")
</template>
<script>
import {mapGetters} from 'vuex'

import BkMigas from '@/components/catalog/Migas-list.vue'
import BkProduct from '@/components/catalog/Product.vue'

export default {
  name: 'ProductSingle',
  components: { BkMigas, BkProduct },
  props: ['id', 'migas'],
  data () {
    return {
      migasSend: []
    }
  },
  computed: {
    ...mapGetters([
      'role'
    ]),
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  created () {
    // console.log('----------+', this.migas)

  },
  methods: {
    toFilterCategory (v) {
      this.$router.push({'name': 'leadOferta', 'params': {'catProductSingle': {id: v}}})
    },
    toAddCart (item) {
      // console.log(`Component page/CatalogoList -> id product: ${item.idProduct}, quantity: ${item.quantity} , item: `, item.product)
      // Envia al carrito
      this.$store.dispatch('addCart', {idProduct: item.idProduct, quantity: item.quantity, product: item.product})

      // Y redirijimos al catálogo
      // this.$router.go(-1)
    },
    toGoProduct (id) {
      // console.log(`-----> toGoProduct: ${id}`)
      this.idProduct = id
      this.$router.push({'name': 'leadProductSingle', 'params': {'id': id.toString(), 'migas': this.migas}})
    },
    toSendMigas (migas) {
      this.migasSend = migas
    }
  },
  watch: {
    migas () {
      // console.log('--------->migas:', this.migas)
    }
  }
}
</script>
<style lang="scss" scoped>

#catalog-migas{
  padding-left: 0;
}

</style>
