import Vue from 'vue'

const formatNumber = {}

formatNumber.install = function (vue) {
  Vue.filter('formatNumber', (val, min = 0, max = 0) => {
    return Number(val).toLocaleString('de-DE', { minimumFractionDigits: min, maximumFractionDigits: max })
  })
}

export default formatNumber
