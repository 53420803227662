<template lang="pug">
  #dashboardVentaPerdidosGrid
    .container(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}
    .container(v-if="count == 0")
      .row
        .col-sm-12
          div(class="alert alert-info" role="alert") #[translate No se han encontrado clientes].
    .holder-cn(v-if="count")
      .container
        .row.box-1
          div.col-sm-5
            p.name #[translate Clientes]
          .col-sm-2
            p.text-center(@click="changeOrdering('yearSales')") #[translate Ventas año] #[i(class="fa" :class="yearSalesStyle", aria-hidden="true")]
          .col-sm-2
            p.text-center.padding-right #[translate Objetivo]
          .col-sm-2
            p.text-center(@click="changeOrdering('previousYearSales')") #[translate Ventas año anterior] #[i(class="fa" :class="previousYearSalesStyle", aria-hidden="true")]
          .col-sm-1
            p.text-center #[translate Ver Centros]

        .row.box-2
          bk-item(v-for="(client, index) in clients" :key="client.id" :client="client" :index="index")

      .container.text-center
        ek-paginate(:page-count="pages"
          :page-range="4"
          :margin-pages="2"
          :click-handler="pagChange"
          :prev-text="paginationTxt.prev"
          :next-text="paginationTxt.next"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :initial-page="currentPage-1"
          v-show="pages")
</template>
<script>
import EkPaginate from 'vuejs-paginate'
import BkItem from '@/components/dashboard/Item-ventas-perdidos.vue'

export default {
  name: 'DashboardVentaPerdidosGrid',
  props: ['clients', 'message', 'pages', 'count', 'currentPage'],
  data () {
    return {
      'paginationTxt': {
        'prev': this.$gettext('Anterior'),
        'next': this.$gettext('Siguiente')
      },
      'aOrderingFull': [], // dónde guardaremos el string de la ordenación.
      'orderingCurrent': null, // donde guardamos el valor único de ordenación.
      'yearSalesOrdering': '',
      'yearSalesStyle': 'fa-sort',
      'previousYearSalesOrdering': '',
      'previousYearSalesStyle': 'fa-sort'
    }
  },
  components: { BkItem, EkPaginate },
  computed: {
    type_app () {
      return this.$config.type
    }
  },
  created () {
    // this.changeOrdering('yearSales') // -year_sales
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.$emit('toPaginate', [pageNum])
    },
    changeOrdering (type) {
      // reset estilos de flechas de ordenación para un único criterio de ordenación
      this.yearSalesStyle = 'fa-sort'
      this.previousYearSalesStyle = 'fa-sort'

      switch (type) {
        case 'yearSales':
          this.yearSalesOrdering = (this.yearSalesOrdering === '-year_sales') ? 'year_sales' : '-year_sales'
          this.yearSalesStyle = (this.yearSalesOrdering === '-year_sales') ? 'fa-sort-asc' : 'fa-sort-desc'
          this.orderingCurrent = this.yearSalesOrdering
          break
        case 'previousYearSales':
          this.previousYearSalesOrdering = (this.previousYearSalesOrdering === '-previous_year_sales') ? 'previous_year_sales' : '-previous_year_sales'
          this.previousYearSalesStyle = (this.previousYearSalesOrdering === '-previous_year_sales') ? 'fa-sort-asc' : 'fa-sort-desc'
          this.orderingCurrent = this.previousYearSalesOrdering
          break
        default:
      }

      // envia un solo criterio de ordenación
      this.$emit('toOrdering', this.orderingCurrent)
    }
  },
  watch: {
    currentPage (num) {
      // Si la pagina es uno reseteamos el ordenaci
      if (num === 1) {
        // console.log('reset ordering')
        this.aOrderingFull = []
        this.yearSalesOrdering = ''
        this.yearSalesStyle = 'fa-sort'
        this.previousYearSalesOrdering = ''
        this.previousYearSalesStyle = 'fa-sort'
      }
    }
  }
}
</script>
<style lang="scss" scoped>

  .box-1{
    display: flex;
    align-items: flex-end;
    padding-top:14px;
    font-size:14px;
    color:#43425D;
    font-weight:$light;
    border-bottom: 1px solid #17296E;
    margin-bottom: 6px;

    [class*='col-'] {
      &:last-child{
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    p{
      position: relative;
      cursor: pointer;

      &.name{
        color: #43425D;
        font-weight: $medium;
        i{
          position: relative;
          margin-left: 8px;
        }
      }

      i{
          display:inline-block;
          color: $blue-light;
          margin-left: 4px;
      }
    }
  }

  .holder-cn{
    margin-top: 4px;
    .box-2{
      padding-bottom: 6px;
      border-bottom: 1px solid #E8E8E8;

      & > * {
        &:nth-child(odd){
          background-color: rgba(#F1F4F7, .38);
        }
      }
    }
  }

  .alert{
    margin-top:40px;
  }

</style>
