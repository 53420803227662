<template>
  <modal :show.sync="syncShow" max-width-class="tw-max-w-[730px]">
    <template #header="{ close }">
      <div class="tw-flex tw-h-32 justify-between tw-items-start tw-p-5 tw-rounded-t tw-border-b-4">
        <img class="tw-absolute tw-top-0 tw-left-0" src="./header.svg" alt="">
        <button @mousedown="close" type="button" class="tw-absolute tw-right-2 tw-text-gray-400 tw-bg-[#4e3c3c80] hover:tw-bg-[#00000080] hover:tw-text-gray-900 tw-rounded-lg tw-text-sm tw-p-1.5 tw-mt-4 tw-ml-auto tw-inline-flex tw-items-center" data-modal-toggle="defaultModal">
          <img src="./close-modal-icon.svg" alt="Close">
        </button>
      </div>
    </template>

    <template #body>
      <div class="tw-px-6 tw-py-4 tw-space-y-4">
        <p class="tw-text-[14px] tw-leading-relaxed tw-text-[#43425D]">Según el Protocolo de gases de efecto invernadero (GEI), las emisiones de gases de efecto invernadero se clasifican en tres “alcances”.  </p>
        <ul class="tw-w-full tw-pl-4 tw-mt-2 tw-mb-3">
          <li class="tw-mb-2">
            <div class="tw-w-fill tw-flex">
              <img class="tw-mt-[0.455rem] tw-w-[0.4rem] tw-h-[0.4rem] tw-h-full" src="./bullet.svg"  alt=""/>
              <span class="tw-ml-2"><span class="tw-font-medium">Alcance 1: </span><span>Son las emisiones directas de fuentes propias o controladas.</span></span>
            </div>
          </li>
          <li class="tw-mb-2">
            <div class="tw-w-fill tw-flex">
              <img class="tw-mt-[0.455rem] tw-w-[0.4rem] tw-h-[0.4rem] tw-h-full" src="./bullet.svg"  alt=""/>
              <span class="tw-ml-2"><span class="tw-font-medium">Alcance 2: </span><span>Son las emisiones indirectas procedentes de la generación de electricidad, vapor, calefacción y refrigeración comprados y consumidos por la empresa.</span></span>
            </div>
          </li>
          <li class="tw-mb-2">
            <div class="tw-w-fill tw-flex tw-mb-3">
              <img class="tw-mt-[0.455rem] tw-w-[0.4rem] tw-h-[0.4rem] tw-h-full" src="./bullet.svg"  alt=""/>
              <span class="tw-ml-2"><span class="tw-font-medium">Alcance 3: </span><span>Comprende las emisiones indirectas que se producen en la cadena de valor de una empresa. Ejemplos de emisiones de alcance 3 son:</span></span>
            </div>
            <ul class="tw-pl-14">
              <li>- Productos y servicios comprados</li>
              <li>- Viajes de negocios</li>
              <li>- Eliminación de residuos</li>
              <li>- Transporte y distribución</li>
              <li>- Inversiones</li>
              <li>- Activos arrendados</li>
            </ul>
          </li>
        </ul>
        <div class="tw-bg-[#E8F4E2] tw-rounded-xl tw-px-5 tw-py-4">Si tienes cualquier consulta adicional al respecto, no dudes en contactar con nosotros y un especialista en Sostenibilidad te informará de lo que necesites.</div>
      </div>
    </template>

    <template #footer="{ close }">
      <div></div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/common/Modal_v2.vue'
import RangeInput from "@/components/finger-print/RangeInput";
export default {
  name: "Modal_v2",
  components: { RangeInput, Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      syncShow: false
    }
  },
  watch: {
    show(state) {
      this.syncShow = state
    },
    syncShow(state) {
      this.$emit('update:show', state)
    }
  }
}
</script>

<style></style>
