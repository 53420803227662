<template>
  <modal :show.sync="syncShow" max-width-class="tw-max-w-[850px]">
    <template #header="{ close }">
      <div
        class="tw-flex justify-between tw-items-start tw-p-4 tw-rounded-t tw-border-b-4"
      >
        <div class="tw-flex tw-gap-8 tw-items-center">
          <div class="tw-w-1/2 tw-pl-3 tw-pr-20">
            <h3 class="tw-text-xl tw-font-semibold tw-text-[#7EC161]">
              ¿Cómo puedo reducir mi huella de Carbono?
            </h3>
          </div>
          <div class="tw-w-1/2 tw-flex tw-flex-col tw-items-center">
            <img
              class="tw-w-[42px]"
              src="./finger-print-logo.svg"
              alt="Finger Print"
            />
            <span>Simulador de emisiones de CO2</span>
          </div>
        </div>
        <button
          @mousedown="close"
          type="button"
          class="tw-text-gray-400 tw-bg-transparent hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-rounded-lg tw-text-sm tw-p-1.5 tw-ml-auto tw-inline-flex tw-items-center"
          data-modal-toggle="defaultModal"
        >
          <img src="./close-modal-icon.svg" alt="Close" />
        </button>
      </div>
    </template>

    <template #body>
      <div class="tw-relative tw-px-6 tw-py-4">
        <div
          :class="{ 'tw-opacity-1': loading, 'tw-opacity-0': !loading }"
          class="tw-pointer-events-none tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center"
        >
          <loading-spinner></loading-spinner>
        </div>
        <div :class="{ 'tw-opacity-0': loading }" class="tw-space-y-4">
          <p class="tw-text-[14px] tw-leading-relaxed tw-text-[#43425D]">
            En el siguiente gráfico puedes modificar el valor en porcentaje del
            Pedido Medio para comprobar su repercusión en las emisiones de CO2 y
            en consecuencia la huella de carbono que produces.
          </p>
          <div
            class="tw-flex tw-items-center tw-rounded-xl tw-px-10 tw-pb-10 tw-pt-14 tw-bg-[#1C2C40] tw-gap-10"
          >
            <div class="tw-flex-1">
              <range-input v-model="variation"></range-input>
            </div>
            <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
              <div class="tw-text-[14px] tw-text-[#F4F6F9]">% variación</div>
              <div class="tw-text-[22px] tw-text-[#35A7FA]">
                {{ variation > 0 ? "+" : "" }}{{ variation }}%
              </div>
            </div>
          </div>
          <div class="tw-grid tw-grid-cols-2 tw-gap-5">
            <div class="tw-flex tw-flex-row tw-rounded tw-bg-[#F4F6F9] tw-pb-2">
              <div class="tw-flex tw-flex-col tw-w-1/2 tw-pl-4 tw-pt-5">
                <div class="tw-pl-4 tw-text-[#43425D] tw-text-[14px]">
                  € / Envío
                </div>
                <div class="tw-pl-4 tw-text-[#707070] tw-text-[24px] tw-mb-3">
                  {{
                    new Intl.NumberFormat("de-DE").format(
                      Math.round(data.costs_per_delivery)
                    )
                  }} €
                </div>
                <div
                  class="tw-flex tw-justify-center tw-border-b tw-border-b-[#707070]"
                >
                  <img src="./truck-one.svg" alt="Truck One" />
                </div>
              </div>
              <div class="tw-flex tw-flex-col tw-w-1/2 tw-pr-4 tw-pt-5">
                <div class="tw-pl-4 tw-text-[#35A7FA] tw-text-[14px]">
                  Simulación
                </div>
                <div class="tw-pl-4 tw-text-[#1592E6] tw-text-[24px] tw-mb-3">
                  {{
                    new Intl.NumberFormat("de-DE").format(
                      Math.round(costSimulation)
                    )
                  }} €
                </div>
                <div
                  class="tw-flex tw-justify-center tw-border-b tw-border-b-[#707070]"
                >
                  <img src="./truck-two.svg" alt="Truck Two" />
                </div>
              </div>
            </div>
            <div class="tw-flex tw-flex-row tw-rounded tw-bg-[#F4F6F9] tw-pb-2">
              <div class="tw-flex tw-flex-col tw-w-1/2 tw-pl-4 tw-pt-5">
                <div class="tw-pl-4 tw-text-[#43425D] tw-text-[14px]">
                  Total km
                </div>
                <div class="tw-pl-4 tw-text-[#707070] tw-text-[24px] tw-mb-3">
                  {{
                    new Intl.NumberFormat("de-DE").format(
                      Math.round(data.total_distance)
                    )
                  }} km
                </div>
                <div
                  class="tw-flex tw-justify-center tw-border-b tw-border-b-[#707070]"
                >
                  <img src="./truck-one.svg" alt="Truck One" />
                </div>
              </div>
              <div class="tw-flex tw-flex-col tw-w-1/2 tw-pr-4 tw-pt-5">
                <div class="tw-pl-4 tw-text-[#35A7FA] tw-text-[14px]">
                  Simulación
                </div>
                <div class="tw-pl-4 tw-text-[#1592E6] tw-text-[24px] tw-mb-3">
                  {{
                    new Intl.NumberFormat("de-DE").format(
                      Math.round(distanceSimulation)
                    )
                  }} km
                </div>
                <div
                  class="tw-flex tw-justify-center tw-border-b tw-border-b-[#707070]"
                >
                  <img src="./truck-two.svg" alt="Truck Two" />
                </div>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-rounded tw-bg-[#F4F6F9] tw-p-4 tw-gap-4">
            <div class="tw-flex tw-w-7/12 tw-flex-col tw-gap-4">
              <div class="tw-flex tw-gap-6">
                <div>
                  <div class="tw-text-[14px]">Emisiones Totales (Kg CO2e)</div>
                  <div class="tw-text-[28px]">
                    {{
                      new Intl.NumberFormat("de-DE").format(
                        Math.round(c02Original)
                      )
                    }} Kg
                  </div>
                </div>
                <div>
                  <div class="tw-text-[#35A7FA] tw-text-[14px]">Simulación</div>
                  <div class="tw-text-[#35A7FA] tw-text-[28px]">
                    {{
                      new Intl.NumberFormat("de-DE").format(
                        Math.round(c02Simulation)
                      )
                    }} Kg
                  </div>
                </div>
              </div>
              <div class="tw-text-[12px]">
                Con este cambio en tu modelo de aprovisionamiento, sería necesario plantar {{ new Intl.NumberFormat("de-DE").format(trees) }} árboles {{ variationCo2 > 0 ? "más" : "menos" }} para compensar el efecto de tu huella de carbono
              </div>
            </div>
            <div class="tw-flex tw-w-5/12 tw-justify-center tw-items-center">
              <div
                class="tw-flex tw-flex-col tw-justify-center tw-text-center tw-w-[160px] tw-px-1 tw-py-2 tw-border-2 tw-border-[#7EC161] tw-rounded-md"
              >
                <div class="tw-text-[14px] tw-text-[#43425D]">% variación</div>
                <div class="tw-text-[28px] tw-text-[#7EC161]">
                  {{ variationCo2 > 0 ? "+" : ""
                  }}{{ Math.round(variationCo2) }}%
                </div>
                <div class="tw-text-[18px] tw-text-[#17702C]">{{ new Intl.NumberFormat("de-DE").format(trees) }} árboles</div>
              </div>
              <img class="-tw-ml-6" src="./trees.svg" alt="Trees" />
            </div>
          </div>
          <p
            class="tw-px-4 tw-text-[11px] tw-leading-relaxed tw-text-[#707070]"
          >
            Cálculos realizados según la norma DIN 16258 y la guía
            <a
              href="https://www.clecat.org/media/CLECAT_Guide_on_Calculating_GHG_emissions_for_freight_forwarding_and_logistics_services.pdf"
              target="_blank"
              class="tw-underline tw-text-[#00ADFC] tw-cursor-pointer"
              >CECLAT</a
            >
            (European Association for Forwarding, Transport, Logistics and
            Customs Services).
          </p>
        </div>
      </div>
    </template>

    <template #footer="{ close }">
      <div></div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/common/Modal_v2.vue";
import RangeInput from "@/components/finger-print/RangeInput";
import Stores from "@/store";
import API from "@/components/finger-print/api";
import LoadingSpinner from "./LoadingSpinner.vue";
export default {
  name: "Modal_v2",
  components: { LoadingSpinner, RangeInput, Modal },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      syncShow: false,
      variation: 0,
      data: {
        trees: "0",
        avg_fuel_consumption: 13.5,
        total_co2e: 0,
        co2e_factor: 3.17,
        total_distance: 0,
        distance_list: [0],
        delivery_number: 0,
        costs_per_delivery: 0,
      },
      loading: true,
    };
  },
  computed: {
    client: () => Stores.getters.client,
    costSimulation() {
      return this.data.costs_per_delivery * (1 + (this.variation < -99 ? -98 : this.variation) / 100);
    },
    deliveryQuantity() {
      return (
        (this.data.delivery_number * this.data.costs_per_delivery) /
        this.costSimulation
      );
    },
    ratio() {
      return this.data.delivery_number / this.deliveryQuantity;
    },
    distanceSimulation() {
      return this.data.total_distance / this.ratio;
    },
    c02Original() {
      return (
        (this.data.total_distance / 100) *
        this.data.avg_fuel_consumption *
        this.data.co2e_factor
      );
    },
    c02Simulation() {
      return (
        (this.distanceSimulation / 100) *
        this.data.avg_fuel_consumption *
        this.data.co2e_factor
      );
    },
    variationCo2() {
      return ((this.c02Simulation - this.c02Original) / this.c02Original) * 100;
    },
    trees() {
      return this.c02Simulation / 167;
    },
  },
  watch: {
    show(state) {
      this.syncShow = state;

      if (this.syncShow) {
        this.getData();
      }
    },
    syncShow(state) {
      this.$emit("update:show", state);
    },
  },
  methods: {
    async getData() {
      this.loading = true;

      const request = await API.getSimulatorInfo();

      if (request.status === 200) {
        this.data = request.data;
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
