<template lang="pug">
  .dashboardVentaRankingListItem.container
    .row.box-1
      div(:class="{'col-sm-5': type !== 'sinActividad', 'col-sm-12': type === 'sinActividad'}")
        p.name {{client.name}}
      .col-sm-3(v-if="type !== 'sinActividad'")
        p.objetivosData.text-center {{client.period_sales | format-money}}
          small #[span(:class="{'menos-cn': client.period_sales_diff_percentage < 0 }") {{client.period_sales_diff_percentage}}%]
      .col-sm-3(v-if="type !== 'sinActividad'")
        p.objetivosData.text-center {{client.period_sales_target | format-money}}
          small(:class="{'menos-cn': client.period_sales_target_percentage < 100 }") {{client.period_sales_target_percentage}}%
      .col-sm-1(v-if="type !== 'sinActividad'")
        p.text-center
          img(:src="img.ver" class="lupa" width="18" alt="" @click="goRankingCenter()")
</template>
<script>
export default {
  name: 'ClientListItem',
  props: ['client', 'index', 'type'],
  data () {
    return {
      'img': {
        'ver': require('@/assets/img/icos/ver_ranking.svg')
      }
    }
  },
  methods: {
    goRankingCenter () {
      // console.log(`goRakingcenter - client ID: ${this.client.id}`)
      this.$router.push({name: 'dashboardVentasRankingCenter', params: {id: this.client.id}})
    }
  },
  computed: {
    type_app () {
      return this.$config.type
    }
  }
}
</script>
<style lang="scss" scoped>
  .box-1{
    display: flex;
    align-items: center;
    padding:8px 0;
    color: #4D4F5C;
    font-size: 14px;
  }

  p{
    position: relative;
    margin: 0;

    small{
      display: block;
      color: #71c061;
      font-size: 14px;

      &.menos-cn, .menos-cn{
        color: #f95d5d;
      }
    }
  }

  .lupa{
    cursor: pointer;
    @include transition();

    &:hover, &:focus{
      opacity: .8
    }
  }
</style>
