<template lang="pug">
  #catalog-menu(:class="`type-${isTypeApp}`")
    ul.nivel-1
      // li
        button( @click="menuToggle('', 0,'')")
          img(:src="img.hambur" width="12")
          translate Todas las categorías

      li(v-if="aCat" v-for="itemN1 in aCat")
        button( @click="menuToggle(itemN1, 1, itemN1.id + '-')" :data-id="itemN1.id")
          div.tw-flex.tw-items-center
            img(:src="itemN1.image_url" width="32" class="imgN1")
            span {{itemN1.name}}
          img(class="imgArrow" :class="{rotate: itemN1.children.length}" :src="img.flecha" width="10")

        ul.nivel-2(v-if="itemN1.children.length" class="collapse scrollCustom" aria-expanded="true" style="height:350px;")
          li(v-for="itemN2 in itemN1.children")
            button( @click="menuToggle(itemN2, 2, itemN1.id + '-' + itemN2.id)" :data-id="itemN2.id")
              img(:src="itemN2.image_url" width="32" height="32" class="imgN2")
              span {{itemN2.name}}

            ul.nivel-3(v-if="itemN2.children.length" class="collapse" aria-expanded="true")
              li(v-for="itemN3 in itemN2.children")
                button(@click="menuToggle(itemN3, 3, itemN1.id + '-' + itemN2.id + '-' + itemN3.id)" :data-id="itemN3.id")
                  img(class="imgArrow" :class="{rotate: itemN3.children.length}" :src="img.flecha" width="6")
                  span {{itemN3.name}}

                ul.nivel-4(v-if="itemN3.children.length" class="collapse" aria-expanded="true")
                  li(v-for="itemN4 in itemN3.children")
                    button(@click="menuToggle(itemN4, 4, itemN1.id + '-' + itemN2.id + '-' + itemN3.id + '-' + itemN4.id)" :data-id="itemN4.id") {{itemN4.name}}

</template>
<script>
// importamos servicios Catálogo
import SrvCategory from '@/services/category'

export default {
  name: 'CatalogMenu',
  props: ['category'],
  data () {
    return {
      aCat: '',
      message: '',
      migas: '',
      timerSetCat: '', // porsi haces click rápidos en el menú sue solo haga la ultima llamada.
      img: {
        hambur: require('@/assets/img/icos/hambur.svg'),
        flecha: require('@/assets/img/icos/menu-down.svg')
      },
      menu: {
        nivel: null
      }
    }
  },
  created () {
    if (!this.getCategoriesPublic) {
      this.getCategories()
    } else {
      this.getCategoriesPublic()
    }
  },
  computed: {
    migasSlug () {
      return this.$store.getters.catalogueMigasSlug
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    isPageCatalogoPublic () {
      if (
        this.config_app.catalogo_public &&
        (
          this.$route.name === 'catalogoListPublic' ||
          this.$route.name === 'productSinglePublic' ||
          this.$route.name === 'catalogoPublicSearch'
        )
      ) {
        return true
      }

      return false
    }
  },
  methods: {
    getCategories () {
      SrvCategory.getCategories()
        .then(res => {
          // console.log('-----', res)
          if (res.status === 200) {
            this.aCat = res.data
            this.$emit('toGetCat', this.aCat)

            if (!this.$route.query.q) {
              // hacemos click en la primera categoría para que se cargen los productos. Si no hay una busqueda.
              setTimeout(() => {
                if (!this.migasSlug) {
                  // console.log('******Iniciallizamos catálogo. Realizando petición por defecto.')
                  window.$(`#catalog-menu .nivel-1 > li`).first().find('button').first().click()
                } else {
                  // console.log(`******Iniciallizamos catálogo. Realizando petición de las migas guardadas: ${this.migasSlug}`)
                  this.setMigasSlug(this.migasSlug)
                }
              }, 100)
            }
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    getCategoriesPublic () {
      SrvCategory.getCategoriesPublic()
        .then(res => {
          // console.log('-----', res)
          if (res.status === 200) {
            this.aCat = res.data
            this.$emit('toGetCat', this.aCat)

            if (!this.$route.query.q) {
              // hacemos click en la primera categoría para que se cargen los productos. Si no hay una busqueda.
              setTimeout(() => {
                if (!this.migasSlug) {
                  // console.log('******Iniciallizamos catálogo. Realizando petición por defecto.')
                  window.$(`#catalog-menu .nivel-1 > li`).first().find('button').first().click()
                } else {
                  // console.log(`******Iniciallizamos catálogo. Realizando petición de las migas guardadas: ${this.migasSlug}`)
                  this.setMigasSlug(this.migasSlug)
                }
              }, 100)
            }
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    setCat (item, migas) {
      // console.log(`Menu setCat -> idCat: ${idCat} , migas: ${migas}`)
      this.buildMigas(migas)
      // cerramos el menú por si esta abierto
      this.$emit('toFilterCategory', item)
    },
    buildMigas (migas) {
      const aMigas = migas.split('-')
      const num = aMigas.length
      const aMigasFinal = []

      // console.log('***' + migas)

      for (let i = 0; i < num; i++) {
        // console.log('+++++' + aMigas[i])
        this.aCat.map((item, index) => {
          // console.log('-index: ' + index + ', item:' + item.id)
          let id = parseInt(aMigas[i])
          if (item.id === id) {
            aMigasFinal.push(item)
            return false
          }

          if (item.children.length) {
            item.children.map((item2, index) => {
              // console.log('--index: ' + index + ', item:' + item2.id)
              if (item2.id === id) {
                aMigasFinal.push(item2)
                return false
              }

              if (item2.children.length) {
                item2.children.map((item3, index) => {
                  // console.log('---index: ' + index + ', item:', item3)
                  if (item3.id === id) {
                    aMigasFinal.push(item3)
                    return false
                  }

                  if (item3.children.length) {
                    item3.children.map((item4, index) => {
                      // console.log('---index: ' + index + ', item:', item3)
                      if (item4.id === id) {
                        aMigasFinal.push(item4)
                        return false
                      }
                    })
                  }
                })
              }
            })
          }
        })
        /* let pp = this.aCat.filter((item) => {
          console.log('---->', item)
          return item.id === aMigas[i]
        }) */
      }
      // console.log('*****', aMigasFinal)
      this.$emit('toGetMigas', [aMigasFinal])
    },
    menuToggle (item, nivel, migas) {
      const menu = window.$(`#catalog-menu`)
      const btn = window.$(`#catalog-menu button[data-id="${item.id}"]`)
      const ul = btn.parent().find('ul')

      if (nivel === 0) {
        // damos a cerrar todo
        menu.find('button').removeClass('open')
        menu.find('ul').removeClass('in')
        this.setCat('', '') // para traer los productos de esa categoría.
      } else {
        const mc = window.$(`.nivel-${nivel}`)
        mc.find('button').removeClass('open')
        mc.find('ul').removeClass('in')

        btn.addClass('open')
        ul.first().addClass('in')

        clearTimeout(this.timerSetCat)
        this.timerSetCat = setTimeout(() => {
          this.setCat(item, migas) // para traer los productos de esa categoría.
        }, 500)
      }

      this.nivel = nivel
    },
    setMigasSlug (v) {
      // console.log('setCategory:', v)
      const aMigas = v.split('-')
      aMigas.map((item, index) => {
        window.$(`#catalog-menu button[data-id="${item}"]`).click()
      })
      // reseteamos las migas si no estamos en la página del detalle del producto.
      if (this.$route.name !== 'productSingle' || this.$route.name !== 'leadProductSingle') {
        this.$store.dispatch('setCatalogueMigasSlug', null)
      }
    }
  },
  watch: {
    category (newValue, oldValue) {
      // console.log(`watch category ---> old: ${oldValue}, new: ${newValue}`)
      if (newValue) {
        // Si viene una categoría.
        window.$(`#catalog-menu button[data-id="${newValue}"]`).click()
      } else {
        // Si viene sin categoría pulsamos el primero.
        window.$(`#catalog-menu button`).first().click()
      }
    },
    migasSlug (newValue, oldValue) {
      if (newValue) {
        this.setMigasSlug(newValue)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  #catalog-menu{
    border-bottom: 1px solid #EFEFEF;

      // Ocultar la primera categoría.
      /* &.type-vario{
        .nivel-1 > li > button{
          visibility: hidden;

          & + ul{
            margin-top: -46px;
          }
        }
      } */
  }

  ul{
      padding-left: 0;
      list-style: none;
  }

  button{
    border:0;
    background-color: transparent;
    width: 100%;
    text-align: left;
    color: #646464;

    img{
      margin-right: 10px;
      vertical-align: initial;
    }

    span, i, img{
      pointer-events: none;
    }
  }

  .nivel-1{
      & > li{
        margin: 3px 0;

        & > button{
          position: relative;
          background-color: #F5F5F5;
          color: #1C2C40;
          font-size: 15px;
          font-weight: $medium;
          padding: 8px 10px;

          &.open{
            background-color: rgba(#1592E6, .1);
            .rotate{
                transform: rotate(90deg);
            }
          }

          & > .imgArrow{
            position: absolute;
            top: 20px;
            right: 10px;
            margin: 0;
            @include transition();
          }
        }
      }

      .imgN1{
        width: 42px;
        height: 42px;
        border: 1px solid #E1E1E1;
        margin-right: 10px;
        vertical-align: middle;
      }
  }

  .nivel-2{
    & > li{
      color: #646464;
      & > button{
        position: relative;
        font-weight: $medium;
        padding:8px 12px;
        display: flex;
        align-items: center;

        &:after{
          content:'';
          position: absolute;
          bottom: 0;
          left: 52px;
          right:0;
          height: 1px;
          background-color: #EFEFEF;
        }

        &.open{
          padding-bottom: 0;
          &:after{
            display: none;
          }
        }
      }

      &:last-child{
        & > button{
          &:after{
            display: none;
          }
        }
      }

      .imgN1{
        @include roundAll100(50%);
        border: 1px solid #E2E2E2;
      }
    }
  }

  .nivel-3{
    margin-left: 54px;
    padding-left: 22px;
    padding-bottom: 6px;
    border-bottom: 1px solid #EFEFEF;

    & > li {
      font-size: 14px;
      line-height: 22px;
      & > button{
        position: relative;

        &.open{
          font-weight: $medium;
          .rotate{
              transform: rotate(90deg);
          }
        }

        .imgArrow{
          position: absolute;
          top: 8px;
          left: -10px;
          margin: 0;
          @include transition();
        }
      }
    }
  }


  .nivel-4{
    padding:0 0 0 20px;

    li{
      line-height: 22px;
    }

    button {
      font-size: 13px;
      font-weight: $light;
      &.open{
        font-weight: $medium;
      }
    }
  }

</style>
