<template lang="pug">
  .listadoDesplegableGrid
    .container
      .row.grid-head
        .col-sm-12
          p(v-if="aCatalog.center") #[translate Centro]: {{ aCatalog.center.name }}
        .col-sm-12
          p(v-if="aCatalog.center") #[translate Código de centro]:  {{ aCatalog.center.external_code }}
        
</template>
<script>

import SrvCatalogGenerator from '@/services/cataloggenerator'

export default {
  name: 'ListadoDesplegableGrid',
  props: ['id', 'showDetalle'],
  data () {
    return {
      'aCatalog': []
    }
  },
  created () {
    if (this.showDetalle) {
      this.getCatalogPDF()
    }
  },
  computed: {
    client () {
      return this.$store.getters.client
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    getCatalogPDF () {
      // console.log('id: ' + this.id)
      SrvCatalogGenerator.getCatalogGenerator(this.id)
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            // console.log(`Pedido single: `, data)

            this.aCatalog = data
            // console.log(`number `, res)
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    }
  },
  watch: {
    showDetalle () {
      if (this.showDetalle) {
        this.getCatalogPDF()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.listadoDesplegableGrid{
  max-width: 1100px;
  margin: 20px auto 0;
}

.listadoDesplegableItem{

  &:nth-child(odd){
    background-color: #F5F7F9;
  }

  &:last-child{

  }
}

.box-3{
  padding-top: 20px;
  border-top: 1px solid rgba(#545454, .46);
  .btn{
    transform: translateX(15px);
    margin-left: 20px;
  }
}

.total-cn{
  padding-right:30px;
}

.status-cn{
  padding-left: 0;
}

</style>
