
// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
// axios.defaults.headers.common['accept-language'] = 'es'
axios.defaults.headers.common['Content-Type'] = 'application/json'
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT'
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization'

export default {
  getCatalogsGenerator (querySearch, page) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      page = (page === undefined) ? 1 : page

      const oClient = Stores.getters.client
      const oCenter = Stores.getters.center

      const idClient = (oClient) ? oClient.id : ''
      const idCenter = (oCenter) ? oCenter.id : ''

      let countryTmp = Stores.getters.userInfo.country
      let country = (countryTmp && countryTmp.id !== 'eu') ? countryTmp.id : ''

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/catalogs/?country=${country}&client=${idClient}&center=${idCenter}&search=${querySearch}&page=${page}`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getCatalogGenerator (id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/catalogs/${id}/`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getCatalogGeneratorPDF (id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.defaults.headers.common['Accept'] = 'application/pdf'
      axios.get(`/catalogs/${id}/get_report/`,{responseType: 'blob'})
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
      axios.defaults.headers.common['Accept'] = 'application/json'
    })
  },
  setCatalogGeneratorPDF (catalogType, catalogId) {
    return new Promise((resolve, reject) => {
      const query = `/catalogs/${catalogId}/resend/`
      const datos = {}
      if (catalogType) {
        datos.type = catalogType
      }

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.patch(query, datos)
        .then(res => {
          // console.log('Catalog add cart: ', res)
          resolve(res)
        })
        .catch(error => {
          // console.log(`setOrder error: ${error.response.status}, error response:`, error.response.data)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error.response)
        })
    })
  }
}
